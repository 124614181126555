import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CircularButton from "../components/CircularButton";
import Palette from "../utils/Palette";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";

export default function Newsletter() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Newsletter"}>
            <div style={{
                fontFamily: 'anton',
                fontSize: windowWidth > 768 ? 80 : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 50
            }}>
                NEWSLETTER
            </div>
            <Container style={{maxWidth: 900, marginBottom: 100,}}>
                <Row style={{marginBottom: 100}}>
                    <Col md={12}>
                        <p>This Weekend: Jakarta Content Week 2022</p>
                        <br />
                        <p>Hi, everyone!</p>
                        <p>We have missed you dearly! How has 2022 treated you so far? Hopefully, you’re happy, healthy, and ready for some exciting events this weekend.</p>
                        <p>Jakarta Content Week is back with a new theme and line-up of creative speakers and curators, ready to share their stories and insights with you.</p>
                        <p>Things are also looking slightly different this year, as we are having the event on-site at the newly refurbished Taman Ismail Marzuki, Jakarta. We are rolling out the red carpets and invite you to participate in our sessions in person: enjoying the company of friends and inventive minds in various discussions, talks, and group activities. This year’s Jacent is also free entry. The more, the merrier!</p>
                        <p>Earlier today (11/11) we had our press conference with Jaktent General Manager Avi Purba, Vice President of Frankfurt Book Fair Claudia Kaiser, one of Jaktent’s curator Diaz Hensuk and co-founder of PiBo Mayumi Haryoto. </p>
                        <p>“This is the first time that Jaktent can staged offline. It is an amazing thing for us, because finally we can have the sessions and interact with the audience directly. Hopefully in the third year we can strengthen Jaktent’s roots to be better and stronger, so the ripple effect can be felt in other cities not only in Jakarta,” explained Avi on the press conference.</p>
                        <p>“The support for Jaktent is shown by the presence of FBF, bringing speakers and books from Germany. In the long term, we can create more collaboration opportunities with the creative industry in Southeast Asia and Germany, focusing on Indonesia,” said Claudia.</p>
                        <p>And as for our sessions this Friday, we had a Toys Photography Workshop with photographer Seno Haryo. And still on the topic of toys, we also had a talk show about Board Game Industry with local game designers Febndy Kwik, Galih Aristo and Amy Adam, moderated by Yani Kurniawan, Jaktent Production Manager. Director of ARKIPEL Film Festival Yuki Aditya and Film Programmer Rizki Lazuardi mapped out Indonesia’s history of cinema, while Food journalist Andreas Maryoto and food critic Kevindra Soemantri discussed the importance of investigative food journalism in Indonesia. Ananda Badudu closed the night with a delightful and serene performance for the full house audience in the Wahyu Sihombing Theater.</p>
                        <p>And we still have more in store for Saturday and Sunday. Curated book market from Patjarmerah, cosplay championship, film screenings, and more discussions. Find the schedule attached below, or check out our social media <a href="https://instagram.com/jaktent" style={{color: "black"}} target="_blank">@jaktent</a> and give us a follow.</p>
                        <p>Wait no more. If you’re in Jakarta this weekend, come and visit. We can’t wait to welcome our old friends and meet some new ones. </p>
                        <br />
                        <p>See you tomorrow!</p>
                        <p>Jaktent Team</p>
                    </Col>
                    <Col md={12} style={{display: "flex", justifyContent: "left"}}>
                        <CircularButton
                            isLink
                            to={'/about'}
                            style={{display: "flex", flexDirection: "row", backgroundColor: Palette.YELLOW, color: "black", alignItems: "center"}}
                        >
                            <FiArrowLeft style={{marginRight: 10}} size={20}/>
                            BACK
                        </CircularButton>
                    </Col>
                </Row>
            </Container>
        </AppContainer>
    )
}