import API from "../utils/API";

export default class ParticipantDAO {
    static participate = async (session_id) => {
        return API.request('v1/participant/participate', 'POST', {
            session_id
        });
    }

    static participateLitTradePass = async () => {
        return API.request('v1/participant/participate', 'POST', {
            session_id : 86
        });
    }

    static getLitTradePassStatus = async () => {
        return API.request('v1/session/86', 'GET');
    }

}