import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row, Tab, Tabs} from "react-bootstrap";

import AgendaComponent from "../components/AgendaComponent";
import AgendaComponent3 from "../components/AgendaComponentV3";
import Palette from "../utils/Palette";
import AgendaComponentV4 from "../components/AgendaComponentV4";
import {FiDownload} from "react-icons/fi";
import AgendaComponentV4Mobile from "../components/AgendaComponentV4Mobile";

export default function Agenda() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false)

  useEffect(()=>{
    setIsMobile(windowWidth < 1080)
  },[windowWidth])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AppContainer title={"Agenda"} removeTopPadding>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '9.75rem',
        fontFamily: 'DM sans',
        color: Palette.BLUE2023,

      }}>
        <Container fluid>
          <Row>
            <Col
              style={{
                fontSize: '2.5rem',
                fontWeight: '700',
                lineHeight: '120%',
                textAlign : "center",
                marginBottom : 30
              }}
              md={12}>
              Agenda
            </Col>
          </Row>
        </Container>
          <Container fluid>
              <Row>
                  <Col xl={12} style={{paddingBottom : 50}}>
                    {
                      isMobile ?
                        <AgendaComponentV4Mobile/>
                        :
                        <AgendaComponentV4/>
                    }
                  </Col>
              </Row>
          </Container>
      </div>

    </AppContainer>
  )
}
