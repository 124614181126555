import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AiOutlineBulb, AiOutlineGlobal, AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import Palette from "../utils/Palette";
import moment from "moment";
import { BiCake } from "react-icons/bi";
import LoadingProgress from "../components/LoadingProgress";
import { useNavigate } from "react-router-dom";
import UserDAO from "../dao/UserDAO";
import swal from "../components/CustomSweetAlert";
import countries from "../utils/Countries";
import PhoneInput from "react-phone-input-2";

const INTERESTS = [
    "Book & Literature",
    "Music",
    "Film",
    "Culinary",
    "IP, Game & Cosplay",
    "Fashion",
    "Architecture & Design",
]

export default function EditProfile() {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [fullName, setFullName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [country, setCountry] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [interests, setInterests] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorFields, setErrorFields] = useState({
        nameError: '',
        dobError: '',
        countryError: '',
        phoneNumberError: '',
        miscError: '',
    });

    const getProfile = async () => {
        try {
            let result = await UserDAO.getSelfProfile();
            setFullName(result.full_name);
            setDateOfBirth(result.birth_date.split("T")[0]);
            setCountry((result.country) ? result.country : "");
            setPhoneNumber((result.phone_number) ? result.phone_number : "");
            setInterests((result.creative_interest) ? result.creative_interest : []);
            setIsDataLoading(false);
        } catch (e) {
            console.log(e);
        }
    }
    const editProfile = async () => {
        if (isSubmitting) return;

        await setIsSubmitting(true);
        let errors = validate();
        if (Object.keys(errors).length !== 0) {
            setErrorFields(errors);
            await setIsSubmitting(false);
            return;
        }
        setErrorFields({});

        let body = {
            full_name: fullName,
            birth_date: dateOfBirth,
            country: country,
            phone_number: phoneNumber,
            creative_interest: interests
        }

        try {
            let result = await UserDAO.editProfile(body);
            await swal.fire({
                title: 'Success',
                text: 'Successfully changed your profile!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            localStorage.setItem('name', fullName);
            navigate('/home');
        } catch (e) {
            if (e.code === "DUPLICATE_EMAIL")
                errors.emailError = "This e-mail address has already been used."
            else {
                errors.miscError = "An error has occurred. Please try again later.";
                console.log(e.error_message);
            }
            setErrorFields(errors);
            setIsSubmitting(false);
        }
    }
    const validate = () => {
        let temp = {};
        if (fullName === "")
            temp.nameError = 'Please fill in your full name.'
        if (dateOfBirth === "")
            temp.dobError = 'Please select your date of birth.'
        if (country === "")
            temp.countryError = 'Please select your country.'
        if (phoneNumber.length < 7)
            temp.phoneNumberError = 'Please enter a valid phone number.'
        return temp;
    }

    const onEnter = async (e) => {
        if (e.key === "Enter") await editProfile();
    }

    const changeInterest = (int) => {
        if (interests.includes(int))
            setInterests(interests.filter(i => i !== int))
        else
            setInterests([...interests, int])
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        if (localStorage.getItem('token') === null)
            return navigate('/login');
        getProfile();
    }, [])

    return (
        <AppContainer title={"Edit Profile"}>
            <div style={{
                fontFamily: 'DM Sans',
                fontWeight: 700,
                fontSize: windowWidth > 768 ? 40 : 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                color: '#046E86',
                marginTop: 50,
                marginBottom: 20
            }}>
                Edit Profile
            </div>
            <div style={{
                fontSize: 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginBottom: 50
            }}>
                Edit your name and birth date here.
            </div>

            {isDataLoading ?
                <div style={{
                    marginBottom: 50,
                    textAlign: 'center',
                }}>
                    <LoadingProgress />
                </div> :
                <Container>
                    <Row>
                        <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <div style={{ position: 'relative' }}>
                                <AiOutlineUser size={20} style={{ color: Palette.BLUE2023, position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                                <input type="text" className="register-input" placeholder="Full Name" required="" style={{
                                    width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                                }} value={fullName} onKeyDown={onEnter} onChange={(e) => setFullName(e.target.value)} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 25 }}>
                            <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.nameError}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <div style={{ position: 'relative' }}>
                                <BiCake size={20} style={{ color: Palette.BLUE2023, position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                                <input className="register-input" type="date" placeholder="Birth Date" required="" max={moment().format('YYYY-MM-DD')} style={{
                                    width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                                }} value={dateOfBirth} onKeyDown={onEnter} onChange={(e) => setDateOfBirth(e.target.value)} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.dobError}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <div style={{ position: 'relative' }}>
                                <AiOutlineGlobal size={20} style={{ color: Palette.BLUE2023, position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                                <Form.Select className="register-input" name="country" style={{
                                    width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                                }} value={country} onChange={(e) => setCountry(e.target.value)}>
                                    <option value="" selected disabled>Country</option>
                                    <option value={"Indonesia"}>Indonesia</option>
                                    <option value="" disabled>-------------------------</option>
                                    {countries.map(obj => (
                                        <option style={{
                                            padding: 0
                                        }} value={obj.country}>{obj.country}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.countryError}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <div style={{ backgroundColor: 'white', position: 'relative' }}>
                                <AiOutlinePhone size={20} style={{ color: Palette.BLUE2023, position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0', zIndex: 2 }} />
                                <PhoneInput
                                    placeholder="Phone Number"
                                    countryCodeEditable={false}
                                    autoFormat={false}
                                    country={'id'}
                                    preferredCountries={['id']}
                                    preserveOrder={['preferredCountries']}
                                    disableDropdown={false}
                                    value={phoneNumber} onChange={(e) => setPhoneNumber(e)}
                                    inputStyle={{
                                        borderRadius: 40,
                                        fontFamily: 'Interstate',
                                        border: '2px solid #046E86',
                                        padding: '15px 10px 18px 130px',
                                        width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                                    }}
                                    buttonStyle={{ margin: '15px 0 15px 70px', backgroundColor: Palette.LIGHT_GRAY, backgroundClip: 'border-box', borderRadius: 5 }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.phoneNumberError}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginBottom: 40, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <div style={{ position: 'relative' }}>
                                <AiOutlineBulb size={30} style={{ color: Palette.BLUE2023, position: 'absolute', left: -130, top: 0, bottom: 270, margin: 'auto 0' }} />
                                <div style={{ position: 'absolute', left: -90, top: 0, bottom: 0, margin: 'auto 0' }}>Interests</div>
                                <Form>
                                    {INTERESTS.map((interest) => (
                                        <div key={`inline-${interest}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label={interest}
                                                name={interest + '-name'}
                                                type="checkbox"
                                                id={interest + '-id'}
                                                checked={interests?.includes(interest)}
                                                onClick={() => changeInterest(interest)}
                                            />
                                        </div>
                                    ))}
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <div style={{ position: 'relative' }}>
                                <div style={{
                                    fontWeight: 700,
                                    backgroundColor: Palette.ORANGE2023,
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    color: 'white',
                                    fontSize: 16,
                                    padding: '15px 45px 15px 45px',
                                    left: 0,
                                    right: 0,
                                    borderRadius: 40,
                                    textAlign: 'center',
                                    width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)'
                                }} onClick={editProfile}>
                                    {isSubmitting ? <LoadingProgress /> : "EDIT PROFILE"}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginBottom: 100, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                            <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.miscError}</p>
                        </Col>
                    </Row>
                </Container>
            }
        </AppContainer>
    )
}
