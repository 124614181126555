import { Button, Card, Col, Container, Dropdown, Row, Modal } from "react-bootstrap";
import { AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
import Palette from "../utils/Palette";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import Utils from "../utils/Utils";
import { BsSearch } from "react-icons/bs";
import { useState } from "react";

export default function Header(props) {
    const [keyword, setKeyword] = useState("")
    const navigate = useNavigate()
    const {
        removeTopPadding,
        windowWidth,
        defaultHeaderMenu,
        headerMenu,
        setHeaderMenu,
        setSidebarOpen,
        setIsQRModalOpen
    } = props;
    const { state } = useLocation();
    const location = useLocation()
    // const onEnter = async (e) => {
    //     if (e.key === "Enter") {
    //         navigate(`/search/${keyword}`)
    //         navigate(0)
    //     }
    // }
    return (
        <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 999 }}>
            <Card style={{
                //margin: windowWidth > 770 ? '30px 120px' : '30px 0',
                padding: (windowWidth > 770) ? "0rem 4rem" : "0 10%",
                width: (removeTopPadding && windowWidth > 770) ? "100%" : null,
                // backgroundColor: (location.pathname.split('/')[1] == 'home') ? Palette.YELLOW2023 : Palette.GREYLIGHT2023,
                backgroundColor: Palette.YELLOW2023,
                //backgroundImage: 'url(/new/Menu-Banner.png)',
                //backgroundSize: "100% 100%",
                //backgroundPosition: 'center',
                //backgroundRepeat: 'no-repeat',
                //backgroundOrigin: 'padding-box',
                position: (removeTopPadding && windowWidth > 770) ? "absolute" : null,
                border: 0,
                borderRadius: 0,
                zIndex: 2,
            }}>
                <Card.Body>
                    <Row
                        style={{
                            fontFamily: 'DM sans'
                        }}
                    >
                        <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                            <Link to={'/home'}>
                                <img src={'/new/logo_fc2.png'} style={{ maxWidth: 130, objectFit: "contain", height: 75 }} alt='logo' />
                            </Link>
                        </Col>

                        <Col xs={8} style={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: 'space-around' }}>
                            {
                                windowWidth > 1388 ?
                                    headerMenu.map((menu, idx) => (
                                        <>
                                            {
                                                menu?.submenu && <Dropdown align="end">
                                                    <DropdownToggle variant="link" style={{
                                                        fontSize: 16,
                                                        fontWeight: 700,
                                                        padding: '0px 0px 0px 17px',
                                                        letterSpacing: .2,

                                                        userSelect: 'none',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                        color: Palette.BLUE2023
                                                    }}>{menu?.title.toUpperCase()}</DropdownToggle>
                                                    <DropdownMenu className="dropdown-nav" style={{ textAlign: 'left', background: Palette.ORANGE2023, left: '10px', borderRadius: 0 }}>
                                                        {
                                                            menu?.submenu?.map((subMenu) => {
                                                                return (
                                                                    <>
                                                                        <DropdownItem onClick={() => {
                                                                            navigate(menu?.route, {
                                                                                state: subMenu?.index
                                                                            });
                                                                        }} className="dropdown-item-nav">
                                                                            {subMenu?.title}
                                                                        </DropdownItem>
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                        {/* <DropdownItem onClick={() => setIsQRModalOpen(true)}>
                                                            Show QR Code
                                                        </DropdownItem>
                                                        <Dropdown.Divider />
                                                        <DropdownItem onClick={() => Utils.logout()}>Logout</DropdownItem> */}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            }
                                            {
                                                !menu.submenu && <Link to={menu.route} style={{ textDecorationLine: 'none' }}>
                                                    <div
                                                        onMouseOver={() => {
                                                            const headerMenuTemp = [...defaultHeaderMenu];

                                                            headerMenuTemp[idx].hovered = true;

                                                            setHeaderMenu(headerMenuTemp)
                                                        }}
                                                        onMouseLeave={() => {
                                                            const headerMenuTemp = [...defaultHeaderMenu];

                                                            setHeaderMenu(headerMenuTemp)
                                                        }}
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: 700,
                                                            padding: '0px 12px 0px 12px',
                                                            letterSpacing: .2,
                                                            userSelect: 'none',
                                                            cursor: 'pointer',
                                                            color: menu.selected || menu.hovered ? Palette.ORANGE2023 : Palette.BLUE2023
                                                        }}>
                                                        {menu.title.toUpperCase()}
                                                    </div>
                                                </Link>
                                            }

                                        </>

                                    )) : null
                            }
                        </Col>

                        <Col xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: '2rem' }}>
                            {
                                windowWidth > 1388 &&
                                <div className="search-nav-container">
                                    <div className="search-icons">
                                        <Button onClick={() => {
                                            props?.toogleSearch(!props.showSearch)
                                        }} variant="link"> <BsSearch size={20} color={Palette.BLUE2023} /></Button>
                                    </div>

                                </div>
                            }

                            {
                                windowWidth <= 1388 ?
                                    <div
                                        onClick={() => setSidebarOpen(true)}
                                        style={{
                                            border: `2px solid ${Palette.BLUE2023}`,
                                            padding: '3px 11px 5px 11px',
                                            marginRight: windowWidth > 770 ? 30 : 0,
                                            cursor: 'pointer'
                                        }}>
                                        <AiOutlineMenu size={20} color={Palette.BLUE2023} />
                                    </div> : null
                            }

                            {windowWidth > 1388 &&
                                <>

                                    {localStorage.getItem("name") === null ?
                                        <Link to={'/login'} style={{ textDecorationLine: 'none' }}>
                                            <div style={{
                                                fontWeight: 700,
                                                backgroundColor: Palette.ORANGE2023,
                                                borderRadius: 100,
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                color: 'white',
                                                fontSize: 16,
                                                padding: '15px 45px 15px 45px'
                                            }}>
                                                LOGIN
                                            </div>
                                        </Link> :
                                        <Dropdown align="end">
                                            <DropdownToggle variant="link" style={{
                                                fontSize: 16,
                                                fontWeight: 700,
                                                padding: '0px 0px 0px 17px',
                                                letterSpacing: .2,
                                                userSelect: 'none',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: Palette.BLUE2023
                                            }}>Hi, {localStorage.getItem("name").split(" ")[0]}!</DropdownToggle>
                                            <DropdownMenu>
                                                <Link to={'/my-events'} className={'dropdown-item'}>
                                                    My Events
                                                </Link>
                                                <Link to={'/edit-profile'} className={'dropdown-item'}>
                                                    Edit Profile
                                                </Link>
                                                <DropdownItem onClick={() => setIsQRModalOpen(true)}>
                                                    Show QR Code
                                                </DropdownItem>
                                                <Dropdown.Divider />
                                                <DropdownItem onClick={() => Utils.logout()}>Logout</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
