import Palette from "../utils/Palette";
import React, {useState} from "react";
import {Spinner} from "react-bootstrap";

export default function CustomLoadingButton({isLoading, onClick, children, customStyle}){

    return <div style={{
        fontWeight: 700,
        backgroundColor: Palette.PRIMARY,
        cursor: 'pointer',
        userSelect: 'none',
        color: 'white',
        fontSize: 16,
        padding: '15px 45px 15px 45px',
        textAlign: 'center',
        marginBottom: 10,
        borderRadius: 40,
        background: '#F3632F',
        disabled : isLoading,
        opacity : isLoading ? 0.8 : 1
    }} onClick={onClick}>
        {children} {isLoading && <Spinner size="sm" animation="border" />}
    </div>
}