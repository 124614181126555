import sweetalert from "sweetalert2";

const swal = {
    fire: (options) => {
        return sweetalert.fire({
            ...options,
            confirmButtonColor: 'rgb(247, 91, 87)',
            customClass: {
                container: 'swal-font',
                ...options.customClass,
            }
        })
    }
}

export default swal;