import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Tabs } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Palette from "../utils/Palette";
import CustomScrollbarTabbing from "../components/CustomScrollbarTabbing";
import SessionDAO from "../dao/SessionDAO";
import _ from "lodash";
import moment from "moment";
import SessionsList from "../components/SessionsList";
import swal from "../components/CustomSweetAlert";
import LoadingProgress from "../components/LoadingProgress";

export default function MyEvents() {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [sessions, setSessions] = useState({});
    const [activePage, setActivePage] = useState(0)
    const [dates, setDates] = useState([
        // { title: '11 Nov', subtitle: "Day 1" },
        // { title: '12 Nov', subtitle: "Day 2" },
        // { title: '13 Nov', subtitle: "Day 3" },
    ]);
    const [dates2, setDates2] = useState([
        // { title: '08 Nov', subtitle: "Day 1" },
        // { title: '09 Nov', subtitle: "Day 2" },
        // { title: '10 Nov', subtitle: "Day 3" },
        // { title: '11 Nov', subtitle: "Day 4" },
        // { title: '12 Nov', subtitle: "Day 5" },
    ]);

    const [eventYear, setEventYear] = useState(2023)
    const [loading, setLoading] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getSessions = async () => {
        try {
            setLoading(true)
            let rawResult = await SessionDAO.getAllBySelf();

            let result = rawResult.filter((obj)=>{
                return obj.event_name !== "JAKTENT_LIT_TRADE"
            })

            console.log("Result from SQL is: ", result)
            // let filteredResult = result?.filter((v) => {
            //     return v.event_name === 'JAKTENT_' + eventYear
            // })
            let yearCheck = []
            for(let a of result) {
                let b = new Date(a.date)
                if(b.getFullYear() === eventYear) {
                    yearCheck.push(a)
                }
            }

            yearCheck.sort((a, b) => new Date(a.start_time)- new Date(b.start_time))
            console.log("Sorted year check is: ", yearCheck)

            let arrGroupTab = []
            let groupedByDate = _.groupBy(yearCheck, (obj) => {
                    arrGroupTab.push(obj.start_time)
                    console.log("arrgroupTab: " + arrGroupTab)
                    return moment(obj.start_time).format("DD MMM")
            })
            console.log("groupedbydate: ", groupedByDate)

            // const tabbGroupMap = _.keys(groupedByDate)?.map((v, index) => {
            //         return {
            //             subtitle: `Day ${index + 1}`,
            //             title: moment(v).format("DD MMM")
            //         }
            // })
            
            let tabbGroupMap
            if(eventYear === 2023){
                tabbGroupMap = [
                {
                    subtitle: `Day 1`,
                    title: "08 Nov"
                },
                {
                    subtitle: `Day 2`,
                    title: "09 Nov" 
                },
                {
                    subtitle: `Day 3`,
                    title: "10 Nov" 
                },
                {
                    subtitle: `Day 4`,
                    title: "11 Nov" 
                },
                {
                    subtitle: `Day 5`,
                    title: "12 Nov" 
                }
            ]
            }else{
                tabbGroupMap = [
                    {
                        subtitle: `Day 1`,
                        title: "11 Nov" 
                    },
                    {
                        subtitle: `Day 2`,
                        title: "12 Nov" 
                    },
                    {
                        subtitle: `Day 3`,
                        title: "13 Nov" 
                    }
                ]
            }
            console.log("type tabgroupmap: ", typeof(tabbGroupMap))
            console.log("tabgroupmap: ", tabbGroupMap)

            await setSessions(groupedByDate);

            setDates(tabbGroupMap)
            setLoading(false)
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    // useEffect(() => {
    //     let date = moment(new Date()).format("DD");
    //     // let date = "12"
    //     if (date === "11") {
    //         setActivePage(0);
    //     }
    //     else if (date === "12") {
    //         setActivePage(1);
    //     }
    //     else if (date === "13") {
    //         setActivePage(2);
    //     }
    // }, [])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        if (localStorage.getItem('token') === null)
            return navigate('/login');
        getSessions();
    }, [eventYear]);

    useEffect(() => {
        const status = searchParams.get('transaction');
        if (status === "settlement" || status === "capture") {
            swal.fire({
                title: "Payment successful",
                text: "Your payment has been successful.",
                icon: "success",
                confirmButtonText: "Ok"
            })
        }
        else if (status === "deny") {
            swal.fire({
                title: "Payment failed",
                text: "Your recent payment has failed. Try again.",
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
        else if (status === "pending") {
            swal.fire({
                title: "Payment in progress.",
                text: "Complete the payment process as soon as possible.",
                icon: "warning",
                confirmButtonText: "Ok"
            })
        }
    }, [searchParams.get('transaction')])

    const styleTabsActive = {
        borderRadius: 0, background: Palette.BLUE2023, borderColor: Palette.BLUE2023
    }

    const styleTabInactive = {
        color: Palette.BLUE2023, borderRadius: 0, borderColor: Palette.BLUE2023
    }

    const handleChangeYear = (year) => {
        console.log('selected year', year)
        if (year !== eventYear) {
            setEventYear(year)
        }
    }

    return (
        <AppContainer title={"My Events"}>
            <div style={{
                fontFamily: 'DM sans',
                fontSize: windowWidth > 768 ? '2.5rem' : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 50,
                fontWeight: 700,
                color: Palette.BLUE2023,
            }}>
                My Events
            </div>
            <Container>
                <Row className="justify-content-end text-end">
                    <Col className={`float-right ml-auto text-end tabs-event-button gap-2 ${windowWidth < 768 ?? 'd-flex'}`}>
                        <Button onClick={() => {
                            handleChangeYear(2023)
                        }} style={eventYear === 2023 ? styleTabsActive : styleTabInactive} variant={eventYear === 2023 ? "primary" : "outline-primary"}>Jaktent 2023</Button>
                        <Button onClick={() => {
                            handleChangeYear(2022)
                        }} style={eventYear === 2022 ? styleTabsActive : styleTabInactive} variant={eventYear === 2022 ? "primary" : "outline-primary"}>Jaktent 2022</Button>
                    </Col>
                </Row>
            </Container>

            <Container fluid style={{ padding: 30, marginBottom: 100 }}>
                <Container>
                    {
                        loading ? <div className="d-flex mb-3 w-100" style={{ justifyContent: 'center', alignItems: 'center', height: '30vh' }}><div style={{ position: 'relative', bottom: 50 }}><LoadingProgress /></div></div>
                            :
                            <>
                                <CustomScrollbarTabbing activePage={activePage} setActivePage={setActivePage} tabs={dates} windowWidth={windowWidth} color={Palette.BLUE2023} />
                                <div style={{
                                    height: 'auto',
                                    color: Palette.BLUE2023,
                                    transition: 'opacity 3s ease',
                                    padding: 15
                                }}>
                                    <SessionsList
                                        sessions={sessions[dates[activePage]?.title]}
                                        windowWidth={windowWidth}
                                        errorMessage="No registered sessions in this date." />
                                </div>
                            </>
                    }


                </Container>
            </Container>

        </AppContainer>
    )
}
