import curator1 from '../assets/curators/John.jpg';
import curator2 from '../assets/curators/Diaz.jpeg';
import curator3 from '../assets/curators/Kukuh.jpg';
import curator4 from '../assets/curators/Vicky.jpg';
import curator5 from '../assets/curators/Kevindra.jpg';
import curator6 from '../assets/curators/Adi.jpeg';
import curator7 from '../assets/curators/Dini.jpg';
import curator8 from '../assets/curators/Forlent.jpg';
import curator9 from '../assets/curators/ZenHae.jpg';
import curator10 from '../assets/curators/Lontar.png';
import curator11 from '../assets/curators/RayJanson2.jpg';
import curator12 from '../assets/curators/DavidIrianto.jpg';
import curator13 from '../assets/curators/Wedha2.jpg';
import curator14 from '../assets/curators/Valencia2.jpg';

const curators = [
    {
        id: 1,
        name: "Adi Osman",
        bio: "Adi Osman managed Layar Terkembang, a movie screening room in Padang, from 2016 to 2018. He was the Artistic Director of Andalas Film Exhibition 2017 and Andalas Film Festival 2018. He also curated Sumbar Film Festival 2018 and Film Daerah Jogja-NETPAC Asian Film Festival 2020. He is currently active in Garak.id and Milisfilem Collective.",
        image: curator6,
        affiliation: "Forum Lenteng"
    },
    /* {
        id: 2,
        name: "Dini Adanurani",
        bio: "Dini organized various film festivals such as Jakarta International Documentary and Experimental Film Festival (ARKIPEL) and UI Film Festival. Her past research with Forum Lenteng includes issues such as Indonesian film archives, Javanese temples, and daily narratives. She also participates in several collective installations, videos, and performance arts.",
        image: curator7,
        affiliation: "Forum Lenteng"
    }, */
    {
        id: 2,
        name: "Valencia Winata",
        bio: "Valencia Winata has collaborated in Jakarta International Documentary and Experimental Film Festival (ARKIPEL) and Milisifilem Collective. She manages film archives in Forum Film Dokumenter and is currently furthering her education in Cultural Studies at the Sanata Dharma University. Her research focuses on film history, its aesthetics and culture.",
        image: curator14,
        affiliation: "Forum Lenteng"
    },
    {
        id: 3,
        name: "Zen Hae",
        bio: "Zen Hae writes short stories, essays, poems, and literary criticisms. His poetry collection titled Paus Merah Jambu (2007) was named Best Literary Work of 2007 by Tempo magazine. He was the co-editor for two editions of Lontar Anthology of Indonesian Short Stories and Antologi Cerpen Indonesia. He also served on the literary committee of the Jakarta Arts Council for two periods (2006–2012). He has worked as chief editor at Komunitas Salihara since 2013.",
        image: curator9,
        affiliation: "Lontar Foundation"
    },
    {
        id: 4,
        name: "John H. McGlynn",
        bio: "John H. McGlynn is a translator and a co-founder of the Lontar Foundation. He has translated several dozen titles under his own name and, through Lontar, has ushered into print more than 200 books on Indonesian language, literature, and culture.",
        image: curator1,
        affiliation: "Lontar Foundation"
    },
/*     {
        id: 5,
        name: "Kevindra Prianto Soemantri",
        bio: "Kevindra is a culinary writer, restaurant critic, and founder of the culinary media Feastin' Indonesia. From 2015 to 2017, he was a columnist for The Jakarta Post. In 2019 he took part in Netflix's Street Food Asia as a culinary expert and has his own culinary show on Southeast Asia Today (SEA Today). Kevindra is also the co-founder of Jakarta Dessert Week, Indonesia's biggest dessert festival with a restaurant week concept, and Museum Boga Indonesia",
        image: curator5,
        affiliation: null
    },
    {
        id: 6,
        name: "Vicky Barreto Simanjuntak",
        bio: "Vicky is a consultant for social impact programs in one of the biggest tech companies globally. Previously, she was the Policy Advisor to the Minister of Cooperatives and Small-Medium Enterprises and also the Impact Evaluation Research Manager for Facebook's SheMeansBusiness program assessment. She studied Development Studies at the University of Sussex, specializing in Governance, Public Policy, and Conflict Resolution.",
        image: curator4,
        affiliation: null
    },
    {
        id: 7,
        name: "Kukuh Rizal Arfianto",
        bio: "Before Kukuh finally decided to take a leap of faith in the music industry, he started with a career ranging from retail, advertising, to film. Now, he is the director and co-founder of the music company Suneater. The label houses emerging names from Indonesia, such as .Feast, Hindia, Lomba Sihir, Agatha Pricilla, Aldrian Risjad, Rayhan Noor, and Mantra Vutura. He’s also active in curating music festivals in Indonesia and overseas.",
        image: curator3,
        affiliation: null
    },
    {
        id: 8,
        name: "Diaz Hensuk",
        bio: "Diaz Hensuk studied at LUCT Malaysia and later became a professional graphic designer by establishing SWG Design in 2009, an independent design studio based in Jakarta. His activities are not only in the studio; besides having several projects in the field of FnB and publications, he is also a design mentor and active in institutions, with his last position as Secretary General of ADGI (Association of Indonesian Graphic Designers) and Chairman of Komite Ekraf Jakarta.",
        image: curator2,
        affiliation: null
    }, */
    {
        id: 5,
        name: "Ray Janson",
        bio: "Ray Janson, a renowned Chef, and Restaurateur, as well as a Podcaster. Ray was a graduate of Le Cordon Bleu Paris and launched his culinary journey by working at some of the world’s top dining establishments, including Noma in Copenhagen and L’atelier de Joël Robuchon St. Germain in Paris. Additionally, he is the proprietor of the Bura Bura Japanese Cocktail Bar and serves as the host of the well-received F&B Podcast, known as Ray Janson Radio.",
        image: curator11,
        affiliation: null
    },
    {
        id: 6,
        name: "David Irianto",
        bio: "David Irianto @tygerd is the cofounder of @greatmind.id, a media centered on advocating mental health and mindful lifestyle. He is not a foreign figure in creative industry, this former head of content of Ideafest 2017-2019 has a demonstrated history involved in many cross-sector socio-creative projects, such as @TEDxJakarta, APMF 2018, #RekaRupaRasa 2018, LivestreamFest, as well as contributing his design or curatorial expertise for Indonesia pavilion in Milan Expo 2015, Salone del Mobile 2018, and Maison et Objet 2019. In 2012 this design graduate went to get a social science magister in psychology, and since then convinced that mental well-being is crucial for creativity to flourish. David now work toward producing meaningful and nutritious content for the mind through Greatmind and other medium via his endeavour at @idelaju, @simpul.group, and @pearpress.",
        image: curator12,
        affiliation: null
    },
    {
        id: 7,
        name: "Wedha Stratesti Yudha",
        bio: "Wedha joined Kompas Gramedia about 15 years ago starting as a junior editor in Bhuana Ilmu Populer publishing house. Currently, she is the foreign rights manager at Gramedia International. Her responsibility mainly is to manage the relationships of publishers in the Gramedia group with overseas publishers for business purposes. Gramedia International also acts as an agent for our seven publishers in the group.",
        image: curator13,
        affiliation: null
    },
]

const groups = [
    {
        id: 1,
        name: "Forum Lenteng",
        bio: "Forum Lenteng is an egalitarian non-profit organization as a means of social and cultural studies development. Forum Lenteng was founded by communication students, artists, researchers, and cultural observers in 2003. The forum was established to develop knowledge of media and art of its members by doing production, documentation, research, and open distribution. The development of this knowledge then becomes the foundation for the community to discuss social issues through art and media. After nineteen years of existence, Forum Lenteng has evolved by developing many programs with support and cooperation from various institutions and communities in Indonesia and other countries across the globe.",
        image: curator8
    },
    {
        id: 2,
        name: "Lontar Foundation",
        bio: "Lontar Foundation is the only organization in the world whose focus is the introduction of Indonesia to the world through literary translations. Since its founding in 1987, Lontar has produced translations of literary work by more than 650 authors, thus enabling the teaching of Indonesian literature anywhere in the world through the medium of English language translations. Lontar has been consistent in its commitment to giving voice to marginalized authors and published the first-ever anthology of Indonesian LGBT literature, the first anthology of literature by political exiles, the first comprehensive anthologies of Indonesian short stories, poetry, and drama, and many more, including the literary work of 200 women authors.",
        image: curator10
    },
]

export default class CuratorDAO {
    static getAll = async () => {
        return curators;
    }
    static getGroups = async () => {
        return groups;
    }
}