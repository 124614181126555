import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineLeft, AiOutlineLock, AiOutlineMail, AiOutlineRight } from "react-icons/ai";
import Palette from "../utils/Palette";
import { Link, useNavigate } from "react-router-dom";
import swal from "../components/CustomSweetAlert";
import UserDAO from "../dao/UserDAO";
import CustomLoadingButton from "../components/CustomLoadingButton";

export default function ForgotPassword() {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [email, setEmail] = useState('');
    const [errorFields, setErrorFields] = useState({
        emailError: '',
        miscError: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const forgotPassword = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        let errors = validate();
        if (Object.keys(errors).length !== 0) {
            setErrorFields(errors);
            await setIsSubmitting(false);
            return;
        }

        let body = {
            email: email,
        }
        try {
            let result = await UserDAO.forgotPassword(body);
            navigate("/reset-password?email=" + email)
            console.log(result);
        } catch (e) {
            if (e.code === "EMAIL_NOT_FOUND")
                errors.emailError = "Invalid e-mail address."
            setErrorFields(errors);
            await setIsSubmitting(false);
        }
    }

    const validate = () => {
        let temp = {};
        if (email === "")
            temp.emailError = 'Please fill in your e-mail address.'
        else if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g))
            temp.emailError = 'Please enter a valid e-mail address.'
        return temp;
    }

    const onEnter = async (e) => {
        if (e.key === "Enter") await forgotPassword();
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Forgot Password"}>
            <div style={{
                fontFamily: 'DM Sans',
                fontWeight: 700,
                fontSize: windowWidth > 768 ? 40 : 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                color: '#046E86',
                marginTop: 50,
                marginBottom: 20
            }}>
                Forgot Password
            </div>
            <div style={{
                fontSize: 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginBottom: 50
            }}>
                To reset your password, please fill in your e-mail address.
            </div>
            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <AiOutlineMail size={20} style={{
                            position: 'absolute',
                            left: 25,
                            color: '#046E86',
                            top: 0,
                            bottom: 0,
                            margin: 'auto 0'
                        }} />

                        <input className="register-input" type="email" name="email" placeholder="E-mail" required="" style={{

                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={email} onKeyDown={onEnter} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.emailError}</p>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <div style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }}>
                            <CustomLoadingButton onClick={async () => { await forgotPassword() }} isLoading={isSubmitting}>
                                RESET PASSWORD
                            </CustomLoadingButton>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginBottom: 50, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.miscError}</p>
                </Col>
            </Row>

        </AppContainer>
    )
}
