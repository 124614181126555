import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Row, Col, Container} from "react-bootstrap";

import speaker1 from "../assets/temp_banner/speaker/team_4.jpg"
import SpeakerCard from "../components/SpeakerCard";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

export default function ComingSoon() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        document.title = 'Under Construction';

        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <Container fluid style={{fontFamily: 'Anton', padding : 0, backgroundColor : "#fdfdf3",overflow: 'hidden',}}>
            <Row>
                <Col
                    style={{
                        width : "100%",
                        textAlign : "center",
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        justifyContent: 'center'
                    }}
                    md={12}>
                    <div>
                       <img src={'/logo.png'} style={{maxWidth: 110}}/>
                    </div>

                    <div style={{
                        fontSize: windowWidth > 768 ? "6em" : '4em',
                        transition: 'font-size .5s',
                    }}>
                        COMING SOON
                    </div>

                    <div style={{
                        fontSize: windowWidth > 768 ? "3em" : '2em',
                        transition: 'font-size .5s',
                        fontFamily: 'Outfit'
                    }}>
                        11-13 November 2022
                    </div>
                </Col>
            </Row>

        </Container>
    )
}
