import Palette from "../utils/Palette";
import { AiFillCalendar } from "react-icons/ai";
import moment from "moment/moment";
import { FaMapMarkerAlt, FaUserTie } from "react-icons/fa";
import AssetProgramIcon from "../assets/categories/PROGRAM_ICON.png";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { useEffect, useState } from "react";
import CategoryIcons from "../misc/CategoryIcons";
import { Link } from "react-router-dom";

export default function AgendaCard({
  TIME_CELL_WIDTH,
  SCHEDULE_CELL_HEIGHT,
  SCHEDULE_CELL_WIDTH,
  EACH_PADDING_WIDTH,
  verticalMargin,
  locationIndex,
  durationInHour,
  session,
  isMobile,
  onClick
}) {


  let [isMouseHover, setIsMouseHover] = useState(false)
  let [floatRight, setFloatRight] = useState(null)

  let [refElement, setRefElement] = useState(null)

  return <div
    onMouseEnter={(evt) => {
      if (!isMobile) {
        setFloatRight(refElement.getBoundingClientRect().x < window.innerWidth / 2)
        setIsMouseHover(true)
      }

    }}
    onMouseLeave={() => {
      if (!isMobile) {
        setIsMouseHover(false)
      }
    }}
  >
    <div
      onClick={() => {
        if (isMobile && onClick) {
          onClick()
        }
      }}
      ref={(ref) => {
        if (ref && ref.offsetLeft && !refElement) {
          if (ref) {
            setRefElement(ref)
          }
          setFloatRight(ref.offsetLeft < window.innerWidth / 2)
        }
      }} style={{
        position: "absolute",
        top: `calc(${verticalMargin}px + 10px + 2px)`,
        left: isMobile ? `calc(${(SCHEDULE_CELL_WIDTH) * locationIndex}vw + ${locationIndex * EACH_PADDING_WIDTH / 2}vw)` : `calc(${TIME_CELL_WIDTH}vw + ${EACH_PADDING_WIDTH}vw + ${(SCHEDULE_CELL_WIDTH) * locationIndex}vw + ${locationIndex * EACH_PADDING_WIDTH / 2}vw)`,//20 itu margin horizontal antar cardnya
        // border : "1px solid black",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{
        height: SCHEDULE_CELL_HEIGHT * durationInHour - 2,//times two because each grid is 30 min only, minus two pixel for border purpose,
        width: SCHEDULE_CELL_WIDTH + "vw",
        // background: Palette.ORANGE2023,
        background: Palette.GREYLIGHT2023,
        color: Palette.BLUE2023,
        padding: 5,
        zIndex: 50,
        cursor: "pointer"
      }}>
        <div style={{ fontWeight: "bold", fontSize: isMobile ? "0.6em" : "1em" }}>{session?.categories[0]?.name}:</div>
        <div style={{ fontWeight: "bold", fontSize: isMobile ? "0.7em" : "1em" }}>{session.name}</div>
        <div style={{ marginTop: isMobile ? 10 : 20, fontSize: isMobile ? ".6em" : "0.8em" }}>
          {session?.speakers?.map((speaker, key) => {
            return speaker.name + (key < session.speakers.length - 1 ? "," : "")
          })}
        </div>
      </div>
      <div
        style={{
          background: "white",
          zIndex: 101,
          marginLeft: floatRight ? 10 : `calc(${-SCHEDULE_CELL_WIDTH - 40}vw - 10px)`,
          boxShadow: "0px 4px 20px 0px #0000001A",
          padding: 20,
          width: "40vw",
          display: isMouseHover ? "block" : "none"
          // height: 270
        }}
      >
        <div className={"d-flex flex-row"}>
          <div style={{ flex: 1 }}>
            <div style={{ fontWeight: 700, fontSize: 20 }}>{session.name}</div>
            <div className={"d-flex align-items-center justify-content-start"}>
              <AiFillCalendar className={"me-1"} />
              <div className={"me-3"}>{moment(session.start_time).format("DD MMM YYYY")}</div>
              <FaMapMarkerAlt className={"me-1"} />
              {session.location}
            </div>
          </div>
          <div>
            {CategoryIcons.renderBadge(999, session?.categories[0]?.name, 60)}
          </div>
        </div>

        <div
          className={"d-flex flex-row"}
          style={{ marginTop: 20, fontSize: "0.8em" }}>
          <Container>
            {
              session?.speakers?.length > 0 ?
                session?.speakers?.map((speaker, key) => {
                  return <Row>
                    <Col className={"d-flex flex-row"} xs={6}>
                      <img
                        style={{
                          marginRight: 10,
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          objectFit: "cover"
                        }}
                        src={speaker.photo} />
                      <div>
                        <div style={{
                          fontWeight: "800",
                          maxWidth: 100,
                          textOverflow: "ellipsis",
                          lineClamp: 1
                        }}>{speaker.name}</div>
                        <div>{speaker.title}</div>
                      </div>
                    </Col>
                  </Row>
                })
                : <div className="d-flex flex-column">
                  <b>Speakers</b>
                  <div>To be announced</div>
                </div>
            }


          </Container>
        </div>

        <div className={"d-flex flex-row justify-content-end mt-4"}>
          <Button
            style={{
              borderRadius: 30,
              background: Palette.ORANGE2023,
              border: "none",
              paddingTop: 10,
              paddingBottom: 10
            }}
            className={"d-flex flex-row align-items-center justify-content-center"}>
            <Link to={"/event/" + session.id}>
              <div
                style={{ textDecoration: "none", color: "white" }}
                className={"link-no-decoration me-2"}>View Detail</div>
            </Link>
            <BsArrowRight />
          </Button>
        </div>
      </div>

      <div style={{
        height: SCHEDULE_CELL_HEIGHT * durationInHour - 2,
        background: "#00000000",
        width: 10,
        zIndex: 101
      }}>

      </div>

    </div>
  </div>
}
