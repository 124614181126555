import React, {useEffect, useState} from "react"
import SessionDAO from "../dao/SessionDAO"
import moment from "moment"
import Palette from "../utils/Palette";
import {Button, Col, Container, Modal, Row, Tab, Tabs} from "react-bootstrap";
import AssetProgramIcon from "../assets/categories/PROGRAM_ICON.png"
import {AiFillCalendar} from "react-icons/ai";
import {FaMapMarkerAlt} from "react-icons/fa";
import {BsArrowRight} from "react-icons/bs";
import AgendaCard from "./AgendaCard";
import {FiDownload} from "react-icons/fi";
import QRCode from "react-qr-code";
import ApiConfig from "../utils/ApiConfig";

const TIME_CELL_WIDTH = 3.5
const SCHEDULE_CELL_HEIGHT = 150
// const SCHEDULE_CELL_WIDTH = 15
const PLACES_CELL_HEIGHT = 50
const BASELINE_HOUR = 10

const PLACES = ['Asrul Sani', 'Sjuman Jaya', 'Teguh Karya', 'Ruang Tari', 'Ruang Sastra']
const TIME = ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30','13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30']
const DATE = ['2023-11-08', '2023-11-09', '2022-11-10', '2022-11-11', '2022-11-12']

let fullWidth = 97
fullWidth = fullWidth - TIME_CELL_WIDTH

let reservedForPadding = fullWidth * .04

let SCHEDULE_CELL_WIDTH = 18
// let SCHEDULE_CELL_WIDTH = (fullWidth - reservedForPadding)/PLACES.length
let EACH_PADDING_WIDTH = reservedForPadding/(PLACES.length - 1)

console.log("SCWEPW", SCHEDULE_CELL_WIDTH, EACH_PADDING_WIDTH)

export default function AgendaComponentV4({date, events}) {

    const CHOSENDATE = DATE[0]

    const [eventsData, setEventsData] = useState([])
    const [selectedDate, setSelectedDate] = useState(moment(DATE[0]).format("DDMMM"))


    const getEventData = async () => {
        try {
            let result2 = await SessionDAO.getAllForCurrentEvent()

            console.log(result2)
            setEventsData(result2)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getEventData()
    }, [])

    return <div className={"d-flex flex-column"}>
        <Container fluid>
            <Row>
                <Col className={"d-flex align-items-end justify-content-start mb-5"} md={8} xs={12}>
                    <div style={{flex : 1}}>
                        <Tabs
                          style={{width: "100%"}}
                          defaultActiveKey={moment(DATE[0]).format("DDMMM")}
                          className={`tabs-agenda`}
                          activeKey={selectedDate}
                          onSelect={(value)=>{
                              console.log("SSDV", value)
                              setSelectedDate(value)
                          }}
                        >
                            {
                                DATE.map((obj,key)=>{
                                    return  <Tab eventKey={moment(obj).format("DDMMM")} title={<div style={{textAlign: "left"}}>
                                        Day {key+1}<br/>
                                        <div style={{fontSize: "0.9em"}}>{moment(obj).format("DD MMM")}</div>
                                    </div>}>
                                    </Tab>
                                })
                            }
                        </Tabs>
                    </div>
                </Col>
                {/* <Col className={"d-flex align-items-end justify-content-end mb-5"} md={4} xs={12}>
                    <Button
                      className={"d-flex flex-row align-items-center justify-content-center ps-4 pe-4"}
                      style={{border : "none", background : Palette.ORANGE2023, color : "white", paddingTop : 10, paddingBottom : 10, borderRadius : 20}}
                    >
                        <div className={"me-2"}>DOWNLOAD AGENDA</div>
                        <FiDownload/>
                    </Button>
                </Col> */}
            </Row>
        </Container>
        <Container fluid style={{
            padding : 0
        }}>
            <Row style={{
                padding : 0
            }}>
                <Col
                  xl={12}
                  className={"d-flex flex-row align-items-start justify-content-start"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        overflowX : "auto",
                        // border : "1px blue solid"
                    }}
                    >
                        {/**RENDER HEADER**/}
                        <div style={{width: "100%"}} className={"d-flex flex-row"}>
                            <div style={{
                                width: TIME_CELL_WIDTH + 'vw',
                                marginRight : EACH_PADDING_WIDTH+"vw"
                            }}>

                            </div>
                            {PLACES.map(obj => {
                                return <div className={"d-flex align-items-center justify-content-center"} style={{
                                    width: SCHEDULE_CELL_WIDTH + "vw",
                                    fontSize: "0.9em",
                                    color: "white",
                                    background: Palette.BLUE2023,
                                    marginRight: (EACH_PADDING_WIDTH/2)+"vw",
                                    // marginLeft: EACH_PADDING_WIDTH/2+"vw",
                                    // paddingTop : 15,
                                    // paddingBottom : 15,
                                    // paddingLeft : 15,
                                    // paddingRight : 15,
                                    borderRadius: 10,
                                    height: PLACES_CELL_HEIGHT,
                                    textAlign : "center"
                                }}>
                                    {obj}
                                </div>
                            })}
                        </div>

                        {/**Spacing between header and content**/}
                        <div style={{height: 10}}>

                        </div>

                        {/**Rendering table body**/}

                        <div className={"d-flex flex-row"}>
                            {/**Rendering time column**/}
                            <div className={"d-flex flex-column"}>
                                {
                                    TIME.map((obj, key) => {
                                        return <div className={"d-flex flex-direction-row"} style={{
                                            height: SCHEDULE_CELL_HEIGHT,
                                        }}>
                                            <div style={{
                                                width: TIME_CELL_WIDTH + "vw",
                                                background: Palette.AGENDA_BLACK_2023,
                                                height: "100%",
                                                color: "white",
                                                textAlign: "center",
                                                paddingTop: 10
                                            }}>
                                                {obj}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            {/**Rendering table contents**/}
                            <div style={{
                                flex : 1,
                                // position : "relative",
                            }}>
                                {
                                    TIME.map((obj, key) => {
                                        return <div className={"d-flex flex-direction-row"} style={{
                                            height: SCHEDULE_CELL_HEIGHT,
                                        }}>
                                            {/*<div style={{*/}
                                            {/*    width: TIME_CELL_WIDTH + "vw",*/}
                                            {/*    background: Palette.AGENDA_BLACK_2023,*/}
                                            {/*    height: "100%",*/}
                                            {/*    color: "white",*/}
                                            {/*    textAlign: "center",*/}
                                            {/*    paddingTop: 10*/}
                                            {/*}}>*/}
                                            {/*    {obj}*/}
                                            {/*</div>*/}
                                            <div className={"d-flex flex-row"} style={{
                                                background: key % 2 === 0 ? Palette.AGENDA_STRIPE_1 : Palette.AGENDA_STRIPE_2,
                                                marginLeft: EACH_PADDING_WIDTH + "vw",
                                                borderTop: "2px dashed #D3D3D3",
                                                width : "100%"
                                            }}>
                                                {/*{PLACES.map((obj2, key) => {*/}
                                                {/*    return <div className={"d-flex align-items-center justify-content-center"} style={{*/}
                                                {/*        width: SCHEDULE_CELL_WIDTH + "vw",*/}
                                                {/*        fontSize: "0.9em",*/}
                                                {/*        // background : key%2===0 ? Palette.AGENDA_STRIPE_1 : Palette.AGENDA_STRIPE_2,*/}
                                                {/*        marginRight: EACH_PADDING_WIDTH/2+"vw",*/}
                                                {/*        marginLeft: EACH_PADDING_WIDTH/2+"vw",*/}
                                                {/*    }}>*/}

                                                {/*    </div>*/}
                                                {/*})}*/}
                                            </div>
                                        </div>
                                    })
                                }

                                {
                                    eventsData.map((obj, key) => {

                                        if (moment(obj.start_time).format("DDMMM") !== selectedDate) {
                                            return
                                        }
                                        // console.log("EDM", obj)
                                        //Calculate Hour and vertical margin
                                        let hourTimeStart = moment(obj.start_time).format("HH")
                                        let hourTimeEnd = moment(obj.end_time).format("HH")
                                        let timeDiffFromBaselineHour = parseInt(hourTimeStart) - BASELINE_HOUR
                                        let verticalMargin = PLACES_CELL_HEIGHT + SCHEDULE_CELL_HEIGHT * timeDiffFromBaselineHour * 2 //kali 2 karena grid nya 30 menit
                                        // let durationInHour = hourTimeEnd - hourTimeStart
                                        let diffMs = (moment(obj.end_time) - moment(obj.start_time));
                                        let diffMins = Math.round(diffMs/60000)
                                        let durationInHour = diffMins/30 

                                        //Calculate Hour and vertical margin
                                        let locationIndex = -1
                                        for (let p in PLACES) {
                                            if (obj.location === PLACES[p]) {
                                                locationIndex = p
                                                break
                                            }
                                        }
                                        if (locationIndex === -1) {
                                            return
                                        }

                                        return <AgendaCard
                                          TIME_CELL_WIDTH={TIME_CELL_WIDTH}
                                          SCHEDULE_CELL_HEIGHT={SCHEDULE_CELL_HEIGHT}
                                          SCHEDULE_CELL_WIDTH={SCHEDULE_CELL_WIDTH}
                                          EACH_PADDING_WIDTH={EACH_PADDING_WIDTH}
                                          verticalMargin={verticalMargin}
                                          locationIndex={locationIndex}
                                          durationInHour={durationInHour}
                                          session={obj}
                                        />
                                    })
                                }

                            </div>
                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}
