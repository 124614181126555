import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Slider from 'react-slick';

import jaktentLogo from "../assets/logo.png"

import aboutUsImages from "../misc/AboutUsImages";
import CircularButton from "../components/CircularButton";
import {FiArrowRight} from "react-icons/fi";
import Palette from "../utils/Palette";

export default function AboutUs() {

    const carouselSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const renderAboutUsSection = () => {
        return <Col
            md={12}
            style={{
                fontFamily: 'DM sans',
                color: Palette.BLUE2023,
                marginTop: '3.75rem',
            }}
        >
            <Container>
                <Row style={{
                    display : "flex",
                    alignItems : "center",
                    fontSize: "2.5em",
                    lineHeight: '120%',
                    fontWeight: 700,
                    textAlign: 'center',
                }}>
                    <div>
                        About Us
                    </div>
                </Row>
                <Row style={{
                    paddingTop: 100,
                    paddingBottom: 100,
                    display: 'flex',
                    /* gap: '3rem', */
                    justifyContent: 'space-between',
                }}>
                    {/* <Col md={1} xs={12}></Col> */}
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: 'flex-start',
                            marginBottom: (windowWidth < 768) ? 40 : undefined,
                            gap: '2.25rem',
                        }}
                        md={6}>
                        <div style={{
                            fontSize: "1.75em",
                            lineHeight: '2.25rem',
                        }}>
                            Welcome to Jaktent!
                        </div>
                        <div style={{
                            fontSize: "1.25em",
                            lineHeight: '1.75rem',
                            opacity: 0.8,
                        }}>
                            Jakarta Content Week (Jaktent) is an international creative festival pioneered
                            and organized by Yayasan Tujuhbelasribu Pulau Imaji (17000 Islands of Imagination
                            Foundation) in collaboration with Frankfurter Buchmesse (FBF). Jaktent brings together
                            major players from various creative industries sectors - publishing, cuisine, fashion,
                            film, design, games, crafts, cosplay, digital startups, and other media.
                        </div>
                    </Col>
                    <Col md={5}>
                        <Slider {...carouselSettings} style={{alignItems: "center"}}>
                            {aboutUsImages.map(photo => {
                                return <img src={photo}/>
                            })}
                        </Slider>
                    </Col>
                    {/* <Col md={1} xs={12}></Col> */}
                </Row>
                <Row style={{marginBottom : 20, gap: '3rem'}}>
                    {/* <Col md={1} xs={12}></Col> */}
                    <Col md={3}>
                        <img
                            style={{
                                width : "100%",
                                objectFit : "contain",
                                marginBottom: (windowWidth < 768) ? 40 : undefined
                            }}
                            src={jaktentLogo}/>
                    </Col>
                    {/* <Col md={1}></Col> */}
                    <Col
                        style={{
                            display : "flex",
                            alignItems : "center",
                            fontSize: "1.25em",
                            lineHeight: '1.75rem',
                            opacity: 0.8,
                        }}
                        md={8}>
                        Jaktent provides a space for them to discuss the latest technological developments,
                        exchange ideas and experiences, showcase products, and engage in transactions. Jaktent
                        aims to generate a creative ecosystem, especially for the Asia Pacific region. As one
                        of the largest international literary and content festivals in Jakarta, Jaktent is also
                        one of the three major initiatives of Jakarta's proposal to become the Unesco City of
                        Literature, putting books and literature at the heart of the city and its people. In 2022,
                        Jaktent will be staged along with the 33rd International Publishers Association World
                        Congress in Jakarta.
                    </Col>
                </Row>
                <Row style={{marginBottom: 100, justifyContent: "center"}}>
                    {/* <Col md={10} style={{
                        display: "flex",
                        flexDirection: (windowWidth < 768) ? "column" : "row",
                        justifyContent: (windowWidth < 768) ? undefined : "right",
                        alignItems: (windowWidth < 768) ? "flex-end" : undefined,
                    }}>
                        <CircularButton
                            isLink
                            to={'/about/newsletter'}
                            style={{display: "flex", flexDirection: "row", backgroundColor: Palette.ORANGE2023, color: "white", alignItems: "center", marginRight: (windowWidth < 768) ? undefined : 20}}
                        >
                            NEWSLETTER
                            <FiArrowRight style={{marginLeft: 10}} size={20}/>
                        </CircularButton>
                        <CircularButton
                            isLink
                            to={'/about/press-release'}
                            style={{display: "flex", flexDirection: "row", backgroundColor: Palette.ORANGE2023, color: "white", alignItems: "center"}}
                        >
                            PRESS RELEASE
                            <FiArrowRight style={{marginLeft: 10}} size={20}/>
                        </CircularButton>
                    </Col> */}
                </Row>
            </Container>
        </Col>
    }

    return (
        <AppContainer title={"About Us"}>
            <Row style={{
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                {renderAboutUsSection()}
            </Row>
        </AppContainer>
    )
}
