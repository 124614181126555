import {Spinner} from "react-bootstrap";
import React from "react";

export default function LoadingProgress() {
    return <>
        <Spinner animation="grow" role="status" size="sm" style={{marginRight: "0.2em"}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <Spinner animation="grow" role="status" size="sm" style={{marginRight: "0.2em"}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <Spinner animation="grow" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </>
}