import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineLeft, AiOutlineLock, AiOutlineMail, AiOutlineRight } from "react-icons/ai";
import Palette from "../utils/Palette";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import swal from "../components/CustomSweetAlert";
import UserDAO from "../dao/UserDAO";
import { FaEnvelope } from "react-icons/fa";

export default function ResetPassword() {

    const navigate = useNavigate();
    const { search } = useLocation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [email, setEmail] = useState('blabla@gmail.com');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();

    const [errorFields, setErrorFields] = useState({
        passwordError: '',
        confPasswordError: '',
        miscError: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const resetPassword = async () => {

        let errors = validate();
        if (Object.keys(errors).length !== 0) {
            setErrorFields(errors);
            await setIsSubmitting(false);
            return;
        }
        setErrorFields({
            passwordError: '',
            confPasswordError: '',
            miscError: '',
        })

        try {

            let params = {
                email,
                token,
                new_password: password,
            }

            let result = await UserDAO.resetPassword(params);

            await swal.fire({
                title: 'Success',
                text: 'Successfully reset password, you can now login.',
                icon: 'success',
                confirmButtonText: 'Okay'
            })

            navigate("/")

        } catch (e) {

            let message = e.error_message ? e.error_message : "An error occurred"

            if (e.code === "TOKEN_NOT_FOUND") {
                message = "Invalid Token and Email Combination"
            }

            await swal.fire({
                title: 'Error',
                text: message,
                icon: 'error',
                confirmButtonText: 'Okay'
            })
            console.log(e);
        }
    }

    const validate = () => {
        console.log("CMP", password, confirmPassword)
        let temp = {};
        if (token === "")
            temp.tokenError = 'Please fill in the token'
        if (password === "")
            temp.passwordError = 'Please fill in a new password.'
        if (confirmPassword === "")
            temp.confPasswordError = 'Please fill in your password again.'
        else if (password !== confirmPassword)
            temp.confPasswordError = 'Password does not match. Try again.'
        return temp;
    }

    const onEnter = async (e) => {
        if (e.key === "Enter") await resetPassword();
    }

    useEffect(() => {

        console.log("sp", searchParams.get("email"))

        if (!searchParams.get("email")) {
            return navigate("/forgot-password")
        }

        setEmail(searchParams.get("email"))

        if (searchParams.get("token")) {
            setToken(searchParams.get("token"))
        }

        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Reset Password"}>
            <div style={{
                fontFamily: 'DM Sans',
                fontWeight: 700,
                fontSize: windowWidth > 768 ? 40 : 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                color: '#046E86',
                marginTop: 50,
                marginBottom: 20
            }}>
                Reset Password
            </div>
            <div style={{
                fontSize: 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginBottom: 50
            }}>
                We have send an email with a token. Please fill in the token found in your email and fill in your new password to continue.
            </div>
            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <AiOutlineMail size={20} style={{ color: '#046E86', position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                        <input className="register-input" disabled={true} type="email" placeholder="email" required="" style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={email} onKeyDown={onEnter} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <AiOutlineLock size={20} style={{ color: '#046E86', position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                        <input className="register-input" type="text" placeholder="Token" required="" style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={token} onChange={(e) => {
                            if (e.target.value.length < 6) {
                                setToken(e.target.value.toUpperCase())
                            }
                        }} onKeyDown={onEnter} />
                    </div>
                </Col>
            </Row>
            {
                errorFields.tokenError && <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                        <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.tokenError}</p>
                    </Col>
                </Row>
            }
            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <AiOutlineLock size={20} style={{ color: '#046E86', position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                        <input className="register-input" type="password" placeholder="Password" required="" style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={password} onKeyDown={onEnter} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </Col>
            </Row>
            {
                errorFields.passwordError && <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                        <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.passwordError}</p>
                    </Col>
                </Row>
            }
            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <AiOutlineLock size={20} style={{ color: '#046E86', position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0' }} />

                        <input className="register-input" type="password" placeholder="Confirm Password" required="" style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={confirmPassword} onKeyDown={onEnter} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                </Col>
            </Row>
            {
                errorFields.confPasswordError &&
                <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                        <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.confPasswordError}</p>
                    </Col>
                </Row>
            }

            <Row>
                <Col style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <div style={{ position: 'relative' }}>
                        <div style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }}>
                            <div style={{
                                fontWeight: 700,
                                backgroundColor: '#F3632F',
                                borderRadius: 40,
                                cursor: 'pointer',
                                userSelect: 'none',
                                color: 'white',
                                fontSize: 16,
                                padding: '15px 45px 15px 45px',
                                textAlign: 'center',
                                marginBottom: 10
                            }} onClick={resetPassword}>
                                RESET PASSWORD
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginBottom: 50, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35 }}>
                    <p style={{ color: "rgb(247, 91, 87)" }}>{errorFields.miscError}</p>
                </Col>
            </Row>

        </AppContainer>
    )
}
