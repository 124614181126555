import AppContainer from "../components/AppContainer"
import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { Scrollbars } from "react-custom-scrollbars"
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import Palette from "../utils/Palette"
import ScheduleCard from "../components/ScheduleCard"
import SessionDAO from "../dao/SessionDAO"
import _ from "lodash"
import CategoryDAO from "../dao/CategoryDAO"
import LoadingProgress from "../components/LoadingProgress"
import SessionsList from "../components/SessionsList"
import CategoryIcons from "../misc/CategoryIcons"
import ParticipantDAO from "../dao/ParticipantDAO"
import { useNavigate } from "react-router-dom"
import swal from "../components/CustomSweetAlert"

export default function Programs() {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [categories, setCategories] = useState({})
	const [sessionsByCategory, setSessionsByCategory] = useState({})
	const [isDataLoading, setIsDataLoading] = useState(true)
	const [activeCategory, setActiveCategory] = useState({})
	const [participationStatusInLitTrade, setParticipationStatusInLitTrade] =
		useState(null)
	const [paymentLink, setPaymentLink] = useState()
	const refScrollbar = useRef(null)
	const bannerRef = useRef(null)
	const [totalPage, setTotalPage] = useState(6)

	const navigate = useNavigate()

	let currentCategory = categories[activeCategory]

	const getEventData = async () => {
		try {
			let result1 = await CategoryDAO.getAll()
			result1[0].selected = true
			setCategories(result1)
			setActiveCategory(result1[0])

			let result2 = await SessionDAO.getAllForCurrentEvent()
			let spreadResult = []
			for (let r of result2) {
				for (let c of r.categories) {
					spreadResult.push({ ...r, category: c.name })
				}
			}

			let groupedByCategory = _.groupBy(spreadResult, "category")
			console.log(groupedByCategory)
			setSessionsByCategory(groupedByCategory)

			if (state) {
				let categoriesTemp = result1?.map((value) => {
					let obj = value
					return Object.assign(obj, { selected: value.id === state ?? true })
				})
				console.log("pick", categoriesTemp)
				setCategories(categoriesTemp)
				if (categoriesTemp.length > 0) {
					let activeCategory = categoriesTemp?.filter((v) => {
						return v.id === state
					})
					setActiveCategory(activeCategory?.length > 0 ? activeCategory[0] : {})
				}
				window.history.replaceState({}, document.title)
			}
			setIsDataLoading(false)
		} catch (e) {
			console.log(e)
		}
	}

	function handleRightScroll() {
        // const { scrollbars } = refScrollbar
		const scroll = refScrollbar.current.getScrollLeft();
		console.log("scrollWidth: ", scroll)
        refScrollbar.current.scrollLeft(scroll + 250)
    }

	const handleLeftScroll = () =>	 {
		
		const scroll = refScrollbar.current.getScrollLeft();
		console.log("scrollWidth: ", scroll)
        refScrollbar.current.scrollLeft(scroll - 250);
    }

	const signUpForLitTrade = async () => {
		console.log(
			isDataLoading,
			!participationStatusInLitTrade,
			participationStatusInLitTrade !== "REJECTED",
			participationStatusInLitTrade
		)
        if (participationStatusInLitTrade === "WAITING") {
			window.open(paymentLink, "_blank")
			return
		}
		if (
			isDataLoading ||
			(participationStatusInLitTrade &&
				participationStatusInLitTrade !== "REJECTED")
		)
			return

		

		setIsDataLoading(true)

		if (localStorage.getItem("token") === null) {
			return navigate("/login")
		}

		try {
			let result2 = await ParticipantDAO.participateLitTradePass()
			console.log("WOYYY", result2)
			if (result2.status === "WAITING") {
				await swal.fire({
					title: "Registration successful.",
					text: "Please complete the payment process to continue.",
					icon: "info",
					confirmButtonText: "Ok"
				})
				window.open(result2.transaction.snap_payment_url, "_blank")
			}
			window.location.reload()
			return
		} catch (e) {
			console.log(e)
		}
		setIsDataLoading(false)
		return
	}

	const getLitTradePassStatus = async (params) => {
		try {
			if (localStorage.getItem("token") === null) {
				return
			}

			let result2 = await ParticipantDAO.getLitTradePassStatus()
			console.log("LITTRADE STATUS", result2)
			/* setSessionData({
                ...params,
                participantStatus: result2.participantStatus,
                transaction: result2.transaction 
            }) */
			setParticipationStatusInLitTrade(
				result2.participantStatus?.status ?? null
			)
			setPaymentLink(result2.transaction?.snap_payment_url ?? null)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getLitTradePassStatus()
	}, [])

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth)
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	const { state } = useLocation()

	useEffect(() => {
		getEventData()
	}, [state])

	const [bannerLoad, setBannerLoad] = useState()
	return (
		<AppContainer title={"Programs"}>
			<div
				style={{
					fontFamily: "DM Sans",
					fontWeight: 700,
					fontSize: windowWidth > 768 ? 40 : 20,
					transition: "font-size .5s",
					textAlign: "center",
					color: "#046E86",
					marginTop: 50,
					marginBottom: 20
				}}
			>
				Programs
			</div>

			<Container
				fluid
				style={{
					textAlign: "center",
					color: "#046E86",
					padding: 30,
					paddingLeft: windowWidth < 600 ? 0 : 30,
					paddingRight: windowWidth < 600 ? 0 : 30,
					marginBottom: 0
				}}
			>
				
				<Container>
				
					{/* <p>Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. </p> */}
					{isDataLoading ? (
						<LoadingProgress />
					) : (
						<div style={{ display: "flex" }}>
							<Button
								onClick={handleLeftScroll}
								variant={'outline-light'}
								style={{ borderWidth: 0, marginRight: 30}}
								size={'sm'}>
								<div>
									<AiOutlineLeft style={{ color: '#046E86', fontWeight: 700 }} size={15} />
								</div>
							</Button>

							<Scrollbars
								ref={refScrollbar}
								style={{ width: "100%" }}
								autoHeight
								renderThumbHorizontal={({ style, ...props }) => (
									<div
										{...props}
										style={{
											...style,
											backgroundColor: "#fff3c3",
											opacity: 0,
											borderRadius: 500
										}}
									/>
								)}
								autoHide
								universal={true}
								hideTracksWhenNotNeeded={true}
							>
								
								<div style={{ display: "flex" }}>
									{categories.filter((c) => sessionsByCategory[c.name]?.length > 0).map((category, idx) => (	
										<div style={{ display: "flex" }}>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													marginRight: 30
												}}
											>
												{/* {sessionsByCategory[category.name]?.length > 0 ? ( */}
												<div
													onClick={async () => {
														const categoriesTemp = [...categories.filter((c) => sessionsByCategory[c.name]?.length > 0)]
														categoriesTemp.forEach((_, idx2) => {
															categoriesTemp[idx2].selected = false
														})
														categoriesTemp[idx].selected = true
														console.log("selected 1", categoriesTemp)
														console.log("selected 2", category)
														setCategories(categoriesTemp)
														await setActiveCategory(category)
													}}
													style={{
														fontSize: windowWidth > 768 ? 24 : 18,
														fontWeight: "700",
														userSelect: "none",
														cursor: "pointer",
														textTransform: "uppercase",
														paddingLeft: 10,
														paddingRight: 10,
														transition: "font-size .5s",
														whiteSpace: "nowrap"
													}}
												>
													{category.name}
												</div>  {/* )
												: null} */}
												<div
													style={{
														backgroundColor: category.selected
															? "#F3632F"
															: "transparent",
														height: 8,
														width: "100%"
													}}
												/>
												{/* 
												{category.hovered || category.selected ? <div style={{
													width: 0,
													height: 0,
													borderWidth: '10px 10px 0px 10px',
													borderColor: '#F3632F transparent transparent transparent',
													borderStyle: 'solid',
													marginBottom: 30
												}} /> : null} */}
											</div>

											{idx !== categories.length - 1 ? (
												<div
													style={{
														height: 30,
														width: 1,
														backgroundColor: "#4f4f5f",
														marginTop: 15,
														marginRight: 30
													}}
												/>
											) : null}
										</div>

									))}
								</div>
								
							</Scrollbars>

							<Button
								onClick={handleRightScroll}
								variant={'outline-light'} style={{
									borderWidth: 0,
									marginLeft: 30,
								}}
								size={'sm'}>
								<div>
									<AiOutlineRight style={{ color: '#046E86', fontWeight: 700 }} size={15} />
								</div>
							</Button>
						</div>
					)}
					
				</Container>
				
			</Container>
			<img
				ref={bannerRef}
				style={{ display: "none" }}
				onLoad={() => {
					setBannerLoad({
						height: bannerRef?.current?.height,
						weight: bannerRef?.current.width,
						fit:
							(bannerRef?.current?.height / bannerRef?.current.width) * 100 +
							"vw"
					})
				}}
				src={activeCategory?.img_banner}
			></img>
			{/*<Container className="program-tab-jumbtron" style={{ background: (activeCategory?.img_banner && activeCategory?.img_banner !== "") ? `url(${activeCategory?.img_banner})` : "#046e86", backgroundSize : "cover"}} fluid>*/}
			{activeCategory?.img_banner !== "" ? (
			<div
				style={{
					height: bannerLoad?.fit,
					backgroundImage: `url(${activeCategory?.img_banner})`,
					backgroundSize: "100% 100%"
				}}
				fluid
			>
				<div className="program-column-jumbtron">
					{!activeCategory?.img_banner && (
						<>
							<h4>{activeCategory?.name}</h4>
							<p>{activeCategory?.description}</p>
						</>
					)}
				</div>
			</div> )
			: null
			}
			{activeCategory.name === "LitTrade" && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						width: "full",
						backgroundColor: Palette.BLUE2023,
						color: "white",
						backgroundImage: "url('/2023/jeruk.svg')",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "right center",
						backgroundSize: "auto 100%"
					}}
				>
					<div
						style={{
							rowGap: "2.25rem",
							padding: "3rem 6.25rem 3rem 6.25rem"
						}}
					>
						<div>
							Get Access to All Sessions in LitTrade for just{" "}
							<span style={{ color: Palette.YELLOW2023 }}>Rp 250k</span>!
						</div>
						<Button
							style={{
								alignItems: "center",
								display: "flex",
								backgroundColor: "white",
								color: Palette.BLUE2023,
								borderColor: "white",
								borderRadius: 999,
								gap: "0.5rem",
								padding: "1rem 1rem 1rem 1.5rem",
								marginTop: "2.25rem"
							}}
							onClick={() => signUpForLitTrade()}
						>
							{participationStatusInLitTrade === "ACCEPTED" &&
								"You are subscribed to LITTRADE PASS."}
							{participationStatusInLitTrade === "WAITING" &&
								"Please complete the payment process."}
							{participationStatusInLitTrade === "PENDING" &&
								"Quota for LitTrade Pass is currently full. We will inform you if there is an opening."}
							{(participationStatusInLitTrade === "REJECTED" ||
								!participationStatusInLitTrade) && (
								<>
									Participate{" "}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M4.5 12H19.5M19.5 12L12.75 5.25M19.5 12L12.75 18.75"
											stroke="#046E86"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</>
							)}
						</Button>
					</div>
				</div>
			)}
			<Container>
				{isDataLoading ? (
					<LoadingProgress />
				) : (
					<div
						style={{
							height: "auto",
							transition: "opacity 3s ease",
							padding: 15
						}}
					>
						<div
							style={{
								display: "flex",
								marginBottom: 60,
								justifyContent: "center"
							}}
						>
							{/*<img src={'icon_white_placeholder.png'}*/}
							{/*     style={{*/}
							{/*         maxWidth: windowWidth > 768 ? 25 : 20,*/}
							{/*         transition: 'max-width .5s',*/}
							{/*         objectFit: 'contain'*/}
							{/*     }}/>*/}
							{/*<div*/}
							{/*    style={{*/}
							{/*        fontWeight: '600',*/}
							{/*        fontSize: windowWidth > 768 ? 30 : 20,*/}
							{/*        transition: 'font-size .5s',*/}
							{/*        marginLeft: 15*/}
							{/*    }}>*/}
							{/*    {activeCategory.name}*/}
							{/*</div>*/}

							{/*{CategoryIcons.renderBadge(windowWidth, activeCategory.name, 300)}*/}
						</div>

						{/*<div style={{*/}
						{/*    marginTop: 15,*/}
						{/*    marginBottom: 60,*/}
						{/*    fontSize: windowWidth > 768 ? 16 : 14,*/}
						{/*    transition: 'font-size .5s'*/}
						{/*}}>*/}
						{/*    {activeCategory.description}*/}
						{/*</div>*/}

						<SessionsList
							sessions={sessionsByCategory[activeCategory.name]}
							windowWidth={windowWidth}
							errorMessage="No sessions found in this category."
						/>
					</div>
				)}
			</Container>
		</AppContainer>
	)
}
