import API from "../utils/API";

export default class UserDAO {
    static register = async (body) => {
        return API.request('v1/user', 'POST', body);
    }
    static login = async (body) => {
        return API.request('v1/user/login', 'POST', body);
    }

    static getSelfProfile = async () => {
        return API.request('v1/user/self', 'GET');
    }

    static editProfile = async (body) => {
        return API.request('v1/user/self', 'PUT', body);
    }

    static forgotPassword = async (body) => {
        return API.request('v1/user/forgotPassword', 'POST', body);
    }
    static resetPassword = async (body) => {
        return API.request('v1/user/resetPassword', 'POST', body);
    }
}