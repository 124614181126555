import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { FaCalendar, FaClock, FaMapPin, FaUserTie } from "react-icons/fa";
import Palette from "../utils/Palette";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import SessionDAO from "../dao/SessionDAO";

import moment from "moment"
import ParticipantDAO from "../dao/ParticipantDAO";
import Utils from "../utils/Utils";
import LoadingProgress from "../components/LoadingProgress";
import swal from "../components/CustomSweetAlert";
import QRCode from "react-qr-code";
import CategoryIcons from "../misc/CategoryIcons";

export default function EventDetail() {

    const { id: eventId } = useParams();
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);
    const [isYouTubeModalOpen, setIsYouTubeModalOpen] = useState(false);
    const [embedLink, setEmbedLink] = useState("");

    const [sessionData, setSessionData] = useState(null)
    const [carouselSettings, setCarouselSettings] = useState({
        appendDots: dots => <ul style={{ bottom: -40 }}>{dots}</ul>,
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    });

    const getData = async () => {
        try {
            let sessionDetail = await SessionDAO.getById(eventId);
            if (sessionDetail.participantStatus === undefined)
                sessionDetail = {
                    ...sessionDetail,
                    participantStatus: null
                }

            await setSessionData(sessionDetail);
            await setIsDataLoading(false);
            console.log(sessionDetail);

            getLitTradePassStatus(sessionDetail)

        } catch (e) {
            console.log(e)
        }
    }

    const getLitTradePassStatus = async (params) => {

        let sd = params ? params : sessionData

        try {

            console.log("LTP", sd)

            if (localStorage.getItem('token') === null || sd.categories[0]?.name !== "LitTrade") {
                return
            }

            let result2 = await ParticipantDAO.getLitTradePassStatus();
            console.log("LITTRADE STATUS", result2)
            setSessionData({
                ...params,
                participantStatus: result2.participantStatus,
                transaction: result2.transaction
            })
        } catch (e) {
            console.log(e)
        }
    }

    const participate = async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);


        if (localStorage.getItem('token') === null) {
            return navigate('/login');
        }

        if (sessionData.categories[0]?.name === "LitTrade") {
            try {
                let result2 = await ParticipantDAO.participateLitTradePass();
                if (result2.status === "WAITING") {
                    await swal.fire({
                        title: "Registration successful.",
                        text: "Please complete the payment process to continue.",
                        icon: "info",
                        confirmButtonText: "Ok"
                    })
                    window.open(result2.transaction.snap_payment_url, '_blank');
                }
                window.location.reload();
                return
            } catch (e) {
                console.log(e);
            }
            setIsSubmitting(false)
            return
        }

        try {
            // let result1 = await SessionDAO.getConflictingSessions(eventId);
            // console.log(result1);
            // if (result1.length > 0) {
            //     let confirm = await swal.fire({
            //         title: "Warning",
            //         html: "This event has a conflicting schedule with an event you have registered. Are you sure you want to proceed?",
            //         icon: 'warning',
            //         showCancelButton: true,
            //         showDenyButton: true,
            //         confirmButtonText: 'Continue',
            //         denyButtonText: 'Show Conflicting Session',
            //         cancelButtonText: 'Cancel',
            //         customClass: {
            //             actions: 'vertical-buttons',
            //             denyButton: 'swal2-cancel'
            //         }
            //     });
            //     if (confirm.isDenied)
            //         return navigate(`/event/${result1[0].id}`);
            //     if (confirm.isDismissed) {
            //         await setIsSubmitting(false);
            //         return;
            //     }
            // }
            let result2 = await ParticipantDAO.participate(eventId);
            if (result2.status === "WAITING") {
                await swal.fire({
                    title: "Registration successful.",
                    text: "Please complete the payment process to continue.",
                    icon: "info",
                    confirmButtonText: "Ok"
                })
                window.open(result2.transaction.snap_payment_url, '_blank');
            }
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    const redirect = async () => {
        if (sessionData.participantStatus.status === "REJECTED")
            return await participate();
        return window.open(sessionData.transaction.snap_payment_url, '_blank');
    }

    useEffect(() => {
        getData();
        setIsSubmitting(false);
    }, [eventId])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={!isDataLoading ? sessionData.name : null}>
            <Container style={{/*  maxWidth: 1200  */ }}>

                {isDataLoading ?
                    <div style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginTop: "4em",
                        marginBottom: "4em",
                        textAlign: "center"
                    }}>
                        <LoadingProgress />
                    </div> :
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em"
                    }}>
                        <Col style={{
                            textAlign: "center",
                            marginTop: "0.5em",
                            marginBottom: "0.5em"
                        }} md={5}>
                            <div style={{
                                //background: "white",
                                //paddingTop: 50,
                                paddingBottom: 50,
                                // display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                            }}>
                                {
                                    ((sessionData.images && sessionData.images.length > 0)|| sessionData.image_url) ?
                                        sessionData.images?.length > 0 ?
                                            <Slider {...carouselSettings} style={{ boxShadow: '10px 10px 0px 0px #282833', marginBottom: 50 }}>
                                                {sessionData.images.map(link => (
                                                    <img alt="session-image" style={{
                                                        width: "100%",
                                                        objectFit: "contain",
                                                    }}
                                                        src={link} />
                                                ))}
                                            </Slider>
                                            :
                                            <img alt="session-image" style={{
                                                width: "100%",
                                                objectFit: "contain",
                                                //boxShadow: '10px 10px 0px 0px #282833'
                                            }}
                                                src={(sessionData.image_url) ? sessionData.image_url : require('../assets/temp_banner/TEMPORARY-POSTER.jpg')} />

                                        :
                                        null
                                }


                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    {sessionData.categories?.map(obj => (
                                        <div style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }}>
                                            {CategoryIcons.renderBadge(windowWidth, obj.name, 200)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col style={{
                            textAlign: "center",
                            //marginTop: "0.5em",
                            alignItems: "center",
                            justifyContent: "center",
                            color: Palette.BLUE2023,
                            fontFamily: 'DM sans',
                        }} md={7}>
                            <Row>
                                <Row style={{
                                    //paddingTop: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    // paddingLeft: "10%",
                                    // paddingRight: "10%",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "left"
                                }}>
                                    <Row>
                                        <div style={{
                                            fontWeight: 700,
                                            lineHeight: '2.27rem',
                                            fontSize: "1.75rem",
                                            //marginTop: 30,
                                            marginBottom: '1.5rem',
                                        }}>
                                            {sessionData.name}
                                        </div>
                                    </Row>
                                    <Row style={{
                                        marginBottom: '3rem',
                                    }}>
                                        <Col xs={6} sm={4}>
                                            <div style={{
                                                backgroundColor: Palette.BLUE2023,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderRadius: 5,
                                                color: "white",
                                                width: "100%",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                marginBottom: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <FaCalendar />
                                                    <b style={{ marginLeft: 5 }}>Date</b>
                                                </div>
                                                {moment(sessionData.start_time).format("DD MMM YYYY")}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4}>
                                            <div style={{
                                                backgroundColor: Palette.BLUE2023,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderRadius: 5,
                                                color: "white",
                                                width: "100%",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                marginBottom: 10,
                                                wordWrap: "normal"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <FaClock />
                                                    <b style={{ marginLeft: 5 }}>Time</b>
                                                </div>
                                                {moment(sessionData.start_time).format("HH:mm")}
                                                -
                                                {moment(sessionData.end_time).format("HH:mm")}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4}>
                                            <div style={{
                                                backgroundColor: Palette.BLUE2023,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderRadius: 5,
                                                color: "white",
                                                width: "100%",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                marginBottom: 10
                                            }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <FaMapPin />
                                                    <b style={{ marginLeft: 5 }}>Location</b>
                                                </div>
                                                {sessionData.location}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <p style={{
                                                //color: "gray",
                                                fontSize: '1.25rem',
                                                lineHeight: '1.75rem',
                                            }}>
                                                <div style={{ whiteSpace: "pre-line", fontWeight: 400, opacity: 0.8, }}>
                                                    {sessionData.description}
                                                </div>
                                                <br />

                                                <b>Speakers</b>
                                                {
                                                    sessionData.speakers?.length === 0 ?
                                                        <div className={`d-flex flex-collumn`}>
                                                            To be announced
                                                        </div>
                                                        :
                                                        <Row style={{ marginTop: '1.5rem' }}>
                                                            {
                                                                sessionData.speakers && sessionData.speakers.map(obj => {
                                                                    return <>
                                                                        <Col
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: windowWidth < 600 ? "row" : "row",
                                                                                alignItems: "center", paddingBottom: 10,
                                                                                textAlign: windowWidth < 600 ? 'start' : 'start',
                                                                                textDecoration: "none",
                                                                            }}
                                                                            lg={4} xs={6} md={4}
                                                                        >
                                                                            <Link
                                                                                style={{
                                                                                    textDecoration: "none",
                                                                                    color: Palette.BLUE2023,
                                                                                }}
                                                                                to={"/speakers/" + obj.id}>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexDirection: windowWidth < 600 ? "row" : "row",
                                                                                        textAlign: windowWidth < 600 ? 'start' : 'start',
                                                                                        alignItems: windowWidth < 600 ? 'center' : 'center',
                                                                                        width: 'fit',
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        alt="speaker-photo"
                                                                                        style={{
                                                                                            width: 60,
                                                                                            height: 60,
                                                                                            borderRadius: 60,
                                                                                            objectFit: "cover",
                                                                                            //border: `solid 2px ${Palette.SECONDARY}`,
                                                                                            marginRight: windowWidth < 600 ? undefined : 10
                                                                                        }}
                                                                                        src={obj.photo ? obj.photo : "https://wellsource.sgp1.digitaloceanspaces.com/jaktent/uploads/file/1666955370768-file.png"} />
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        flex: 1,
                                                                                        fontWeight: 400,
                                                                                        //fontSize: "0.8em"
                                                                                    }}>
                                                                                        <b>{obj.name}</b>
                                                                                        <div
                                                                                            style={{ /* fontSize: "0.8em" */ fontWeight: 400, opacity: 0.8, }}>{obj.title}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                    </>
                                                                })
                                                            }
                                                        </Row>
                                                }
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: 'flex-end'
                                            }}
                                            >
                                                {(sessionData.participantStatus !== null) ?
                                                    <div
                                                        style={{
                                                            maxWidth: 250,
                                                            fontWeight: 700,
                                                            backgroundColor: sessionData.participantStatus?.status === "ACCEPTED" ? Palette.AGENDA_GREEN : 'orange',
                                                            cursor: (sessionData.participantStatus?.status === "WAITING" || sessionData.participantStatus?.status === "REJECTED") ? 'pointer' : 'default',
                                                            userSelect: 'none',
                                                            color: 'white',
                                                            fontSize: 16,
                                                            padding: '15px 15px 15px 15px',
                                                            marginTop: 10,
                                                            paddingLeft: 20,
                                                            textAlign: "center",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            borderRadius: 100
                                                        }} onClick={(sessionData.participantStatus?.status === "WAITING" || sessionData.participantStatus?.status === "REJECTED") && redirect}>
                                                        <div style={{ textAlign: "left" }}>
                                                            {sessionData.participantStatus?.status === "PENDING" ? "IN QUEUE" : sessionData.participantStatus?.status}
                                                            <br />
                                                            <div style={{ fontSize: "0.7em" }}>
                                                                {sessionData.participantStatus?.status === "ACCEPTED" && "You are registered to this event."}
                                                                {sessionData.participantStatus?.status === "WAITING" && "Please complete the payment process."}
                                                                {sessionData.participantStatus?.status === "PENDING" && "Pending for confirmation."}
                                                                {sessionData.participantStatus?.status === "REJECTED" && "Payment expired. Click to register again."}
                                                            </div>
                                                        </div>

                                                        {sessionData.participantStatus.status === "ACCEPTED" &&
                                                            <FiCheck style={{ marginLeft: 6 }} size={20} />}
                                                        {(sessionData.participantStatus.status === "REJECTED" && !isSubmitting) &&
                                                            <FiArrowRight style={{ marginLeft: 6 }} size={20} />}
                                                        {(sessionData.participantStatus.status === "REJECTED" && isSubmitting) &&
                                                            <Spinner style={{ marginLeft: 6, width: 20, height: 20 }} animation='border' />}
                                                        {sessionData.participantStatus.status === "WAITING" &&
                                                            <Spinner style={{ marginLeft: 6, width: 20, height: 20 }} animation='border' />}
                                                    </div> :
                                                    <div style={{}}>
                                                        <div
                                                            onClick={participate}
                                                            onMouseOver={() => setBtnHovered(true)}
                                                            onMouseLeave={() => setBtnHovered(false)}
                                                            style={{
                                                                maxWidth: 250,
                                                                fontWeight: 700,
                                                                backgroundColor: !isBtnHovered ? Palette.ORANGE2023 : 'transparent',
                                                                border: `1px solid ${Palette.ORANGE2023}`,
                                                                cursor: 'pointer',
                                                                userSelect: 'none',
                                                                color: !isBtnHovered ? 'white' : Palette.ORANGE2023,
                                                                fontSize: 16,
                                                                padding: '15px 15px 15px 15px',
                                                                marginTop: 10,
                                                                paddingLeft: 20,
                                                                textAlign: "center",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                borderRadius: 100
                                                            }}>
                                                            <div style={{ textAlign: "left" }}>
                                                                {sessionData.categories[0]?.name === "LitTrade" ? "Purchase LitTrade Pass" : "PARTICIPATE"}
                                                                <br />
                                                                <div style={{ fontSize: "0.7em" }}>
                                                                    Price
                                                                    : {(sessionData.price && parseInt(sessionData.price) !== 0) ? ("Rp" + Utils.moneyFormatter(sessionData.price)) : "Free"}
                                                                </div>
                                                            </div>

                                                            {isSubmitting ?
                                                                <Spinner animation='border'
                                                                    style={{ marginLeft: 6, width: 20, height: 20 }} /> :
                                                                <FiArrowRight style={{ marginLeft: 6 }} size={20} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            {(sessionData.participantStatus === null && parseInt(sessionData.price) !== 0 && sessionData.quota !== 0) &&
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div style={{ whiteSpace: "pre-line", textAlign: "right", marginTop: 10 }}>
                                                        {sessionData.quota} total slots available.
                                                    </div>
                                                </div>
                                            }
                                        </Col>

                                        <Col md={12}>
                                            {sessionData.participantStatus?.status === "ACCEPTED" &&
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div onClick={() => setIsQRModalOpen(true)} style={{
                                                        maxWidth: 250,
                                                        fontWeight: 700,
                                                        backgroundColor: isBtnHovered ? Palette.PRIMARY : 'transparent',
                                                        border: `1px solid ${Palette.PRIMARY}`,
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                                        fontSize: 16,
                                                        padding: '15px 15px 15px 15px',
                                                        marginTop: 10,
                                                        paddingLeft: 20,
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 100,
                                                    }} onMouseOver={() => setBtnHovered(true)} onMouseLeave={() => setBtnHovered(false)}>
                                                        <div style={{ textAlign: "left" }}>
                                                            SHOW QR CODE
                                                            <div style={{ fontSize: "0.7em" }}>
                                                                Show this QR code on entrance.
                                                            </div>
                                                        </div>
                                                        <FiArrowRight style={{ marginLeft: 6 }} size={20} />
                                                    </div>
                                                </div>
                                            }
                                        </Col>

                                        <Col md={12}>
                                            {sessionData.categories[0]?.name === "LitTrade" && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            whiteSpace: "pre-line",
                                                            textAlign: "right",
                                                            marginTop: 10,
                                                            borderRadius: "0.5rem",
                                                            borderColor: Palette.BLUE2023,
                                                            borderWidth: "2rem",
                                                            backgroundColor: "white",
                                                            padding: "0.5rem 0.75rem",
                                                            display: "inline-flex",
                                                            fontFamily: 'DM Sans',
                                                            fontWeight: 700,
                                                            lineHeight: '1.5rem',
                                                            fontSize: '1rem',
                                                            alignItems: 'center',
                                                            //color: Palette.BLUE2023,
                                                        }}
                                                    >
                                                    
                                                        {
                                                            sessionData?.participantStatus?.status === "ACCEPTED" ?
                                                            <>You are subscribed to LITTRADE PASS</>:
                                                            <>LITRADE PASS grants access to all sessions in LITRADE!</>
                                                        }
                                                        {" "}
                                                         <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM10.956 10.558C12.102 9.985 13.393 11.021 13.082 12.264L12.373 15.1L12.415 15.08C12.5912 15.0025 12.7905 14.9958 12.9715 15.0612C13.1526 15.1265 13.3016 15.259 13.3877 15.4312C13.4737 15.6033 13.4903 15.802 13.434 15.9861C13.3777 16.1702 13.2527 16.3255 13.085 16.42L13.045 16.442C11.898 17.015 10.607 15.979 10.918 14.736L11.628 11.9L11.586 11.92C11.4975 11.9692 11.4 11.9999 11.2994 12.0104C11.1987 12.0209 11.097 12.0109 11.0003 11.981C10.9036 11.9511 10.8139 11.902 10.7367 11.8366C10.6595 11.7711 10.5964 11.6907 10.551 11.6002C10.5057 11.5098 10.4792 11.411 10.4731 11.31C10.4669 11.209 10.4813 11.1078 10.5153 11.0124C10.5493 10.9171 10.6022 10.8297 10.6709 10.7553C10.7396 10.681 10.8226 10.6214 10.915 10.58L10.956 10.558ZM12 9C12.1989 9 12.3897 8.92098 12.5303 8.78033C12.671 8.63968 12.75 8.44891 12.75 8.25C12.75 8.05109 12.671 7.86032 12.5303 7.71967C12.3897 7.57902 12.1989 7.5 12 7.5C11.8011 7.5 11.6103 7.57902 11.4697 7.71967C11.329 7.86032 11.25 8.05109 11.25 8.25C11.25 8.44891 11.329 8.63968 11.4697 8.78033C11.6103 8.92098 11.8011 9 12 9Z"
                                                                fill="#046E86"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            )}
                                        </Col>

                                        <Col md={12}>
                                            {(sessionData.streaming_url !== "" && moment(sessionData.start_time).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) &&
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div onClick={() => {
                                                        setIsYouTubeModalOpen(true);
                                                        setEmbedLink(sessionData.streaming_url.replace("watch?v=", "embed/"))
                                                    }} style={{
                                                        maxWidth: 250,
                                                        fontWeight: 700,
                                                        backgroundColor: Palette.YELLOW,
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        color: Palette.DARK_GRAY,
                                                        fontSize: 16,
                                                        padding: '15px 15px 15px 15px',
                                                        marginTop: 10,
                                                        paddingLeft: 20,
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 100,
                                                    }}>
                                                        <div style={{ textAlign: "left" }}>
                                                            WATCH STREAM
                                                            <div style={{ fontSize: "0.7em" }}>
                                                                Click to watch the live stream.
                                                            </div>
                                                        </div>
                                                        <FiArrowRight style={{ marginLeft: 6 }} size={20} />
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                        <Col md={12}>
                                            {(sessionData.recording_url !== "") &&
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div onClick={() => {
                                                        setIsYouTubeModalOpen(true);
                                                        setEmbedLink(sessionData.recording_url.replace("watch?v=", "embed/"))
                                                    }} style={{
                                                        maxWidth: 250,
                                                        fontWeight: 700,
                                                        backgroundColor: Palette.YELLOW,
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        color: Palette.DARK_GRAY,
                                                        fontSize: 16,
                                                        padding: '15px 15px 15px 15px',
                                                        marginTop: 10,
                                                        paddingLeft: 20,
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 100,
                                                    }}>
                                                        <div style={{ textAlign: "left" }}>
                                                            WATCH RECORDING
                                                            <div style={{ fontSize: "0.7em" }}>
                                                                Click to watch past recordings.
                                                            </div>
                                                        </div>
                                                        <FiArrowRight style={{ marginLeft: 6 }} size={20} />
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Row>

                            </Row>
                        </Col>
                    </Row>
                }
            </Container>

            {(!isDataLoading && sessionData.participantStatus !== null) &&
                <Modal show={isQRModalOpen} fullscreen centered onClick={() => setIsQRModalOpen(false)} size={"lg"}>
                    <Modal.Body style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <p style={{ textAlign: "center", fontSize: "1.2em" }}>{sessionData.name}</p>
                        <p style={{ textAlign: "center", fontSize: "1.2em" }}>{localStorage.getItem("name")}</p>
                        <br />
                        <QRCode size={512} viewBox={`0 0 512 512`} style={{ maxWidth: '75%', height: 'auto' }} value={`${sessionData.participantStatus.id}`} />
                        <br />
                        <p style={{ textAlign: "center", fontSize: "1.2em" }}>Show this QR code to the committee before joining the session.</p>
                        <p style={{ textAlign: "center", fontSize: "1em", color: Palette.RED, fontWeight: "bold" }}>Tap/click on the screen to exit.</p>
                    </Modal.Body>
                </Modal>
            }

            {!isDataLoading &&
                <Modal show={isYouTubeModalOpen} size="xl" centered onHide={() => {
                    setIsYouTubeModalOpen(false);
                    setEmbedLink(null);
                }}>
                    <Modal.Header closeButton />
                    <Modal.Body style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: windowWidth >= 1200 ? 700 : windowWidth > 991 ? 500 : 300 }}>
                        <iframe src={embedLink} width="100%" height="100%" />
                    </Modal.Body>
                </Modal>
            }
        </AppContainer>
    )
}