import { useEffect, useState } from "react";
import AppContainer from "../components/AppContainer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import vanue from '../assets/2023/maps.png'
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
export default function Maps() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Maps"}>
            <div style={{
                fontFamily: 'DM Sans',
                fontWeight: 700,
                fontSize: windowWidth > 768 ? 40 : 20,
                transition: 'font-size .5s',
                textAlign: 'center',
                color: '#046E86',
                marginTop: 50,
                marginBottom: 20
            }}>
                Maps
            </div>
            <Container>
                <Row className="mb-5">
                    <Col md={12}>
                        <Tabs
                            defaultActiveKey="maps"
                            id="justify-tab-example"
                            className={`mb-3 tabs-map ${windowWidth > 768 ? '' : 'nav-justified'}`}

                        >
                            {/* <Tab eventKey="venue" title="VENUE BLUE PRINT">
                                <LazyLoadImage
                                    height={'100%'}
                                    src={vanue} // use normal <img> attributes as props
                                    width={'100%'} />
                            </Tab> */}
                            <Tab eventKey="maps" title="HOW TO GET THERE">
                                <div className="map-responsive">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5631919581747!2d106.83719887468273!3d-6.189158593798441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f4392e3b5a1b%3A0xeb14a935dca46518!2sTaman%20Ismail%20Marzuki!5e0!3m2!1sen!2sid!4v1689303527710!5m2!1sen!2sid" width="600" height="450" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>

                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>


        </AppContainer>

    )
}