import { Col } from "react-bootstrap";
import speaker1 from "../assets/temp_banner/speaker/team_4.jpg";
import React, { useState } from "react";

import { BsArrowRightCircleFill } from "react-icons/bs"
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function SpeakerCard(props) {

    const { speaker, isMobile, xs, sm, md, lg, xl, noPadding, height, activeColor } = props;

    let actualActiveColor = activeColor ? activeColor : "#70090190"

    const [isHovered, setIsHovered] = useState(false)

    const renderName = (name) => {
        let fontSize;

        if (isMobile) {
            fontSize = "1.5em";
        }
        else {
            let longest = 0;
            let names = name.split(" ");
            for (let word of names) {
                if (word.length > longest) longest = word.length;
            }

            if (longest > 15)
                fontSize = "0.8em";
            else if (longest > 10)
                fontSize = "1.5em";
            else
                fontSize = "2em";
        }

        return <div
            style={{
                fontSize: fontSize,
                fontFamily: "DM sans",
                lineHeight: "1em",
                whiteSpace: "pre-line"
            }}>
            {name.replace(/\s/g, "\n")}
        </div>
    }

    return <Col
        onMouseEnter={() => {
            setIsHovered(true)
        }}
        onMouseLeave={() => {
            setIsHovered(false)
        }}
        style={{
            marginTop: 10,
            marginBottom: 10,
            padding: noPadding ? 0 : null,
            width: '100%'
        }}
        xs={xs ? xs : 12}
        sm={sm ? sm : 6}
        md={md ? md : 4}
        lg={lg ? lg : 4}
        xl={xl ? xl : 4}
    >

        <div
            className={"transition-element"}
            style={{
                height: height,
                background: `url(${speaker.photo}) center`,
                backgroundSize: "cover",
                "-moz-filter": (isHovered || isMobile) ? null : `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
                "-o-filter": (isHovered || isMobile) ? null : `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
                "-webkit-filter": (isHovered || isMobile) ? null : `grayscale(100%)`,
                // background: isHovered ? `linear-gradient(to top, #75050550, #b5070770), url(${speaker1}) center` : `linear-gradient(to top, #750505aa, #b50707cc), url(${speaker1}) center`,
                // background : `rgba(39,62,84,0.82), url(${speaker1}) 0 0 no-repeat fixed;`,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
            }}>
            {/*<div style={{*/}
            {/*    backgroundColor : "#ffffff100",*/}
            {/*    height : "500px",*/}
            {/*    marginBottom : "500px"*/}
            {/*}}>*/}
            {/*    bla*/}
            {/*</div>*/}
            <div
                className={"transition-element"}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    paddingBottom: "1em",
                    // marginBottom: isHovered ? 0 : "-2em",
                    color: "white",
                    height: height,
                    flex: 1,
                    backgroundColor: (isHovered || isMobile) ? actualActiveColor : "#00000030"
                }}
            >
                <Link
                    style={{ textDecoration: 'none', color: "white", flex: 1 }}
                    to={"/speakers/" + speaker.id}
                >
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        flex: 1,
                        cursor: "pointer",

                    }}
                    >
                        <div style={{ position: 'relative', left: '2rem' }}>
                            {speaker.name.split(" ").map(word => {
                                let fontSize = (word.length > 15) ? "0.9em" : (word.length > 10) ? "1.5em" : "2em";
                                fontSize = (isMobile) ? "1.5em" : fontSize;
                                return <p style={{ fontSize: fontSize, fontFamily: "DM sans", lineHeight: "1em", marginBottom: "5px", fontWeight: 700 }}>{word}</p>
                            })}
                            <div
                                className={"transition-element"}
                                style={{ fontWeight: 400, marginTop: "0.5em", marginBottom: 0 }}>
                                {speaker.title}
                            </div>
                            <div
                                className={"transition-element"}
                                style={{ fontSize: "2em", height: (isHovered || isMobile) ? "2em" : 0 }}>
                                <BsArrowRightCircleFill
                                    className={"transition-element"}
                                    style={{
                                        height: (isHovered || isMobile) ? null : 0
                                    }}
                                />
                            </div>
                        </div>





                    </div>
                </Link>

                <div
                    className={"transition-element"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        paddingTop: "2em",
                        paddingRight: "1em",
                        width: (isHovered || isMobile) ? "3.5em" : 0
                    }}>
                    {
                        (isHovered || isMobile) &&
                        <>
                            {speaker.facebook_url &&
                                <a href={speaker.facebook_url} target={"_blank"}>
                                    <div style={{
                                        borderRadius: "100%",
                                        height: "2em",
                                        width: "2em",
                                        border: "2px solid white",
                                        fontSize: "1.1em",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                    }}>
                                        <FaFacebook />
                                    </div>
                                </a>
                            }
                            {speaker.instagram_url &&
                                <a href={speaker.instagram_url} target={"_blank"}>
                                    <div style={{
                                        borderRadius: "100%",
                                        height: "2em",
                                        width: "2em",
                                        border: "2px solid white",
                                        fontSize: "1.1em",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                    }}>
                                        <FaInstagram />
                                    </div>
                                </a>
                            }
                            {speaker.linkedin_url &&
                                <a href={speaker.linkedin_url} target={"_blank"}>
                                    <div style={{
                                        borderRadius: "100%",
                                        height: "2em",
                                        width: "2em",
                                        border: "2px solid white",
                                        fontSize: "1.1em",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                    }}>
                                        <FaTwitter />
                                    </div>
                                </a>
                            }
                        </>
                    }
                </div>

            </div>
        </div>
    </Col>
}

