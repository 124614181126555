import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {AiFillCalendar} from "react-icons/ai";
import moment from "moment/moment";
import {FaMapMarkerAlt} from "react-icons/fa";
import CategoryIcons from "../misc/CategoryIcons";
import Palette from "../utils/Palette";
import {BsArrowRight} from "react-icons/bs";
import React from "react";
import {Link} from "react-router-dom";

export default function AgendaDetailModal({
                                            selectedSession,
                                            onClose
                                          }) {
  return <Modal
    style={{
      borderRadius: 0
    }}
    contentClassName="no-radius-modal"
    show={!!selectedSession} centered size={"lg"}>
    <Modal.Body
      style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
      <div className={"d-flex flex-column"}>
        <div className={"d-flex flex-row"}>
          <div style={{flex: 1}}>
            <div style={{fontWeight: 700, fontSize: 20, color : Palette.BLUE2023}}>{selectedSession?.name}</div>
            <div className={"d-flex align-items-center justify-content-start mt-2"}>
              <AiFillCalendar className={"me-1"}/>
              <div className={"me-3"}>{moment(selectedSession?.start_time).format("DD MMM YYYY")}</div>
              <FaMapMarkerAlt className={"me-1"}/>
              {selectedSession?.location}
            </div>
          </div>
          <div className={"d-flex align-items-start justify-content-end"}>
            {CategoryIcons.renderBadgeNoContainer(selectedSession?.categories ? selectedSession?.categories[0]?.name : "", 70, 80)}
          </div>
        </div>

        <div
          className={"d-flex flex-row"}
          style={{marginTop: 20, fontSize: "0.8em"}}>
          <Container>
            <Row>
              {selectedSession?.speakers?.map((speaker, key) => {
                return <Col className={"d-flex flex-row"} xs={6}>
                  <img
                    style={{
                      marginRight: 10,
                      width: 50,
                      height: 50,
                      borderRadius: 50,
                      objectFit: "cover"
                    }}
                    src={speaker.photo}/>
                  <div style={{flex : 1}}>
                    <div style={{
                      fontWeight: "800",
                      maxWidth: 100,
                      textOverflow: "ellipsis",
                      lineClamp: 1
                    }}>{speaker.name}</div>
                    <div style={{
                      textOverflow: "ellipsis",
                      lineClamp: 2,
                      width : "100%"
                    }}>{speaker.title}</div>
                  </div>
                </Col>
              })}
            </Row>
          </Container>
        </div>
        <div className={"d-flex flex-row justify-content-end align-items-end mt-4"}>
          <Button
            style={{
              borderRadius: 30,
              background: Palette.GREYMEDIUM2023,
              border: "none",
              paddingTop: 10,
              paddingBottom: 10,
              marginRight : 10
            }}
            onClick={() => {
              onClose()
            }}>
            Close
          </Button>
          <Button
            style={{
              borderRadius: 30,
              background: Palette.ORANGE2023,
              border: "none",
              paddingTop: 10,
              paddingBottom: 10
            }}
            className={"d-flex flex-row align-items-center justify-content-center"}>
            <Link to={"/event/"+selectedSession?.id}>
              <div
                style={{color : "white"}}
                className={"me-2 link-no-decoration"}>View Detail</div>
            </Link>
            <BsArrowRight/>
          </Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
}