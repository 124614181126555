import Palette from "../utils/Palette";
import {Link} from "react-router-dom";
import React from "react";

export default function CustomLoadingButton({onClick, children, isLink, to, style}) {

    if (isLink) {
        return <Link to={to} style={{textDecorationLine: 'none'}}>
            <div
                style={{
                    fontWeight: 700,
                    backgroundColor: Palette.ORANGE,
                    cursor: 'pointer',
                    userSelect: 'none',
                    color: 'white',
                    fontSize: 16,
                    padding: '15px 15px 15px 15px',
                    marginTop: 20,
                    textAlign: "center",
                    borderRadius: 100,
                    ...style
                }}>
                {children}
            </div>
        </Link>
    }

    return <div
        onClick={()=>{
            onClick()
        }}
        style={{
            fontWeight: 700,
            backgroundColor: Palette.ORANGE,
            cursor: 'pointer',
            userSelect: 'none',
            color: 'white',
            fontSize: 16,
            padding: '15px 15px 15px 15px',
            marginTop: 20,
            width: 100,
            textAlign: "center",
            borderRadius: 100,
            ...style
        }}>
        {children}
    </div>

}