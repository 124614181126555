import React, {useEffect, useState} from "react"
import SessionDAO from "../dao/SessionDAO"
import moment from "moment"
import Palette from "../utils/Palette";
import {Button, Col, Container, Modal, Row, Tab, Tabs} from "react-bootstrap";
import AssetProgramIcon from "../assets/categories/PROGRAM_ICON.png"
import {AiFillCalendar} from "react-icons/ai";
import {FaMapMarkerAlt} from "react-icons/fa";
import {BsArrowRight} from "react-icons/bs";
import AgendaCard from "./AgendaCard";
import {FiDownload} from "react-icons/fi";
import CategoryIcons from "../misc/CategoryIcons";
import AgendaDetailModal from "./AgendaDetailModal";

const TIME_CELL_WIDTH_SMALL = 11
const SCHEDULE_CELL_WIDTH_SMALL = 33
const EACH_PADDING_WIDTH_SMALL = 3

const TIME_CELL_WIDTH_MEDIUM = 8
const SCHEDULE_CELL_WIDTH_MEDIUM = 30
const EACH_PADDING_WIDTH_MEDIUM = 1

const PLACES_CELL_HEIGHT = 50
const SCHEDULE_CELL_HEIGHT = 75
const BASELINE_HOUR = 13


export default function AgendaComponentV4Mobile({date, events}) {

  const PLACES = ['Wahyu Sihombing Theatre', 'Belajar Room', 'Berkarya Room', 'Sjuman Djaja Theatre', 'Emiria Soenassa Gallery', 'S. Sudjojono Gallery']
  const TIME = ['13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30']
  const DATE = ['2022-11-11', '2022-11-12', '2022-11-13']

  const [eventsData, setEventsData] = useState([])
  const [selectedDate, setSelectedDate] = useState(moment(DATE[0]).format("DDMMM"))

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMedium, setIsMedium] = useState(false)

  const [selectedSession, setSelectedSession] = useState(null)

  let TIME_CELL_WIDTH = isMedium ? TIME_CELL_WIDTH_MEDIUM : TIME_CELL_WIDTH_SMALL
  let SCHEDULE_CELL_WIDTH = isMedium ? SCHEDULE_CELL_WIDTH_MEDIUM : SCHEDULE_CELL_WIDTH_SMALL
  let EACH_PADDING_WIDTH = isMedium ? EACH_PADDING_WIDTH_MEDIUM : EACH_PADDING_WIDTH_SMALL

  useEffect(() => {
    setIsMedium(windowWidth > 600)
  }, [windowWidth])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])


  const getEventData = async () => {
    try {
      let result2 = await SessionDAO.getAllForCurrentEvent()

      console.log(result2)
      setEventsData(result2)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getEventData()
  }, [])

  return <div className={"d-flex flex-column"}>
    <Container fluid>
      <Row>
        {
          !isMedium && <Col className={"d-flex align-items-end justify-content-end mb-5"} xl={12}>
            <Button
              className={"d-flex flex-row align-items-center justify-content-center ps-4 pe-4"}
              style={{
                border: "none",
                background: Palette.ORANGE2023,
                color: "white",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 20
              }}
            >
              <div className={"me-2"}>DOWNLOAD AGENDA</div>
              <FiDownload/>
            </Button>
          </Col>
        }

        <Col className={"d-flex align-items-end justify-content-start mb-5"} xl={12}>
          <div style={{flex: 1}}>
            <Tabs
              style={{width: "100%"}}
              defaultActiveKey={moment(DATE[0]).format("DDMMM")}
              className={`tabs-agenda`}
              activeKey={selectedDate}
              onSelect={(value) => {
                console.log("SSDV", value)
                setSelectedDate(value)
              }}
            >
              {
                DATE.map((obj, key) => {
                  return <Tab eventKey={moment(obj).format("DDMMM")}
                              title={<div style={{textAlign: "left"}}>
                                Day {key + 1}<br/>
                                <div
                                  style={{fontSize: "0.9em"}}>{moment(obj).format("DD MMM")}</div>
                              </div>}>
                  </Tab>
                })
              }
            </Tabs>
          </div>
        </Col>

        {
          isMedium && <Col className={"d-flex align-items-end justify-content-end mb-5"} xl={12}>
            <Button
              className={"d-flex flex-row align-items-center justify-content-center ps-4 pe-4"}
              style={{
                border: "none",
                background: Palette.ORANGE2023,
                color: "white",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 20
              }}
            >
              <div className={"me-2"}>DOWNLOAD AGENDA</div>
              <FiDownload/>
            </Button>
          </Col>
        }
      </Row>
    </Container>
    <Container fluid>
      <Row>
        <Col
          style={{overflowX: "scroll"}} xl={12}
          className={"d-flex flex-row align-items-start justify-content-start"}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            // background : "green",
            width: "100%",
            overflowX: "clip"
          }}
          >
            {/**RENDER FROZEN COLUMN FOR TIME**/}
            <div style={{
              // width : 500,
              width: TIME_CELL_WIDTH + "vw",
              paddingTop: `calc(10px + ${PLACES_CELL_HEIGHT}px)`
            }}>
              {
                TIME.map((obj, key) => {
                  return <div className={"d-flex flex-column"} style={{
                    height: SCHEDULE_CELL_HEIGHT,
                  }}>
                    <div style={{
                      width: TIME_CELL_WIDTH + "vw",
                      background: Palette.AGENDA_BLACK_2023,
                      height: "100%",
                      color: "white",
                      textAlign: "center",
                      paddingTop: 10
                    }}>
                      {obj}
                    </div>
                  </div>
                })
              }
            </div>

            {/**RENDER SCROLLABLE STUFF**/}
            <div style={{flex: 1, overflow: "scroll", position: "relative", marginLeft: 10}}>
              {/**RENDER HEADER**/}
              <div style={{display: "flex", flexDirection: "row", width: "100%", overflowX: "visible"}}>
                <div style={{
                  width: EACH_PADDING_WIDTH + 'vw'
                }}>

                </div>
                {PLACES.map((obj, key) => {
                  return <div className={"d-flex align-items-center justify-content-center"} style={{
                    position: "absolute",
                    width: SCHEDULE_CELL_WIDTH + "vw",
                    fontSize: "0.85em",
                    color: "white",
                    background: Palette.BLUE2023,
                    marginRight: (EACH_PADDING_WIDTH / 2) + "vw",
                    // marginLeft: EACH_PADDING_WIDTH+"vw",
                    borderRadius: 10,
                    height: PLACES_CELL_HEIGHT,
                    textAlign: "center",
                    left: `calc(${(SCHEDULE_CELL_WIDTH) * key}vw + ${key * EACH_PADDING_WIDTH / 2}vw)`,//20 itu margin horizontal antar cardnya
                  }}>
                    {obj}
                  </div>
                })}
              </div>

              {/**Spacing between header and content**/}
              <div style={{height: 10}}>

              </div>

              {/**Rendering table body**/}
              <div style={{marginTop: `calc(${PLACES_CELL_HEIGHT}px)`}}>
                {
                  TIME.map((obj, key) => {
                    return <div className={"d-flex flex-direction-row"} style={{
                      height: SCHEDULE_CELL_HEIGHT,
                    }}>
                      <div className={"d-flex flex-row"} style={{
                        background: key % 2 === 0 ? Palette.AGENDA_STRIPE_1 : Palette.AGENDA_STRIPE_2,
                        borderTop: "2px dashed #D3D3D3"

                      }}>
                        {PLACES.map((obj2, key) => {
                          return <div
                            className={"d-flex align-items-center justify-content-center"}
                            style={{
                              width: SCHEDULE_CELL_WIDTH + "vw",
                              fontSize: "0.9em",
                              // background : key%2===0 ? Palette.AGENDA_STRIPE_1 : Palette.AGENDA_STRIPE_2,
                              marginRight: (EACH_PADDING_WIDTH / 2) + "vw",
                              // marginLeft: 10,
                            }}>

                          </div>
                        })}
                      </div>
                    </div>
                  })
                }

                {
                  eventsData.map((obj, key) => {
                    console.log("EDM", obj)

                    if (moment(obj.start_time).format("DDMMM") !== selectedDate) {
                      return
                    }

                    //Calculate Hour and vertical margin
                    let hourTimeStart = moment(obj.start_time).format("HH")
                    let hourTimeEnd = moment(obj.end_time).format("HH")
                    let timeDiffFromBaselineHour = parseInt(hourTimeStart) - BASELINE_HOUR
                    let verticalMargin = PLACES_CELL_HEIGHT + SCHEDULE_CELL_HEIGHT * timeDiffFromBaselineHour * 2 //kali 2 karena grid nya 30 menit
                    let durationInHour = hourTimeEnd - hourTimeStart

                    //Calculate Hour and vertical margin
                    let locationIndex = -1
                    for (let p in PLACES) {
                      if (obj.location === PLACES[p]) {
                        locationIndex = p
                        break
                      }
                    }
                    if (locationIndex === -1) {
                      return
                    }

                    return <AgendaCard
                      onClick={()=>{
                          setSelectedSession(obj)
                      }}
                      TIME_CELL_WIDTH={TIME_CELL_WIDTH}
                      SCHEDULE_CELL_HEIGHT={SCHEDULE_CELL_HEIGHT}
                      SCHEDULE_CELL_WIDTH={SCHEDULE_CELL_WIDTH}
                      EACH_PADDING_WIDTH={EACH_PADDING_WIDTH}
                      verticalMargin={verticalMargin}
                      locationIndex={locationIndex}
                      durationInHour={durationInHour}
                      session={obj}
                      isMobile={true}
                    />
                  })
                }

              </div>
            </div>

          </div>
        </Col>
      </Row>
    </Container>
    <AgendaDetailModal
      selectedSession={selectedSession}
      onClose={()=>{
        setSelectedSession(null)
      }}
    />

  </div>
}
