import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

// import banner1 from '../assets/banners/Banner_Slide-3.jpg';
// import banner2 from '../assets/banners/Banner_Slide-1.jpg';
// import banner3 from '../assets/banners/Banner_Slide-2.jpg';
import banner4 from '../assets/banners/banner1.jpg';
import hero from '../assets/2023/hero.jpg';
import imgmaps from '../assets/2023/maps.png';
import aboutUsImages from "../misc/AboutUsImages";
import partnerImages from "../misc/PartnerImages";
import sponsorLogo from "../assets/temp_banner/frankfurterbookmese.png"
import Palette from "../utils/Palette";
import SpeakerCard from "../components/SpeakerCard";
import Slider from "react-slick";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import SpeakerDAO from "../dao/SpeakerDAO";
import LoadingProgress from "../components/LoadingProgress";
import AgendaComponentV2 from "../components/AgendaComponentV2";
import SectionHeading from "../components/SectionHeading";
import ScheduleCard from '../components/ScheduleCard'
import CircularButton from "../components/CircularButton"
import CuratorDAO from "../dao/CuratorDAO";
import { FaArrowRight } from "react-icons/fa";
import Pagination from 'react-bootstrap/Pagination';
import SessionDAO from "../dao/SessionDAO";
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
export default function Home() {

    const banners = [hero]

    const [mainCarouselSettings, setMainCarouselSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false
    })

    const [speakerCarouselSettings, setSpeakerCarouselSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [speakers, setSpeakers] = useState([]);
    const [curators, setCurators] = useState([]);
    const [isSpeakerDataFetched, setIsSpeakerDataFetched] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(2);

    const [sessions, setSessions] = useState([])

    const indexOfLastPost = currentPage * postsPerPage;

    const currentPageData = sessions
        .slice(indexOfLastPost, indexOfLastPost + postsPerPage);
    const pageCount = Math.ceil(sessions.length / postsPerPage);


    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    const renderSession = (postData) => {
        return (
            <Row style={{ marginTop: 15 }}>
                {postData?.slice(0, 2).map((obj, index) => {
                    return <ScheduleCard
                        customClassCard={'p-0'}
                        customStyleCard={{ position: 'relative', right: index === 0 ? 20 : 0 }}
                        md={6}
                        session={obj}
                        windowWidth={windowWidth}
                    />
                })}
            </Row>
        )
    }

    const getSession = async () => {
        try {
            let result = await SessionDAO.getHighlightedSession()
            setSessions(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getSpeakers = async () => {
        try {
            let result = await SpeakerDAO.getAll();
            await setSpeakers(result);
            setIsSpeakerDataFetched(true);
        } catch (e) {
            console.log(e);
        }
    }

    const getCurators = async () => {
        try {
            let result = await CuratorDAO.getAll();
            console.log(result)
            await setCurators(result);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getSession();
        getSpeakers();
        getCurators();
    }, []);

    useEffect(() => {
        const carouselSettingsTemp = { ...speakerCarouselSettings };

        if (windowWidth < 768) {
            carouselSettingsTemp.slidesToShow = 1
        } else if (windowWidth < 992) {
            carouselSettingsTemp.slidesToShow = 2
        } else {
            carouselSettingsTemp.slidesToShow = 4
        }

        setSpeakerCarouselSettings(carouselSettingsTemp)
    }, [windowWidth])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])


    const renderCarouselSection = () => {
        let settings = {
            ...mainCarouselSettings,
            swipe: false
        }
        return (
            <Slider{...settings}>
                {
                    banners.map((obj, key) => {
                        return <Col
                            style={{
                                width: "100%",
                                padding: 0,
                                backgroundColor: Palette.YELLOW2023,

                            }}
                            md={12}>
                            <img
                                style={{
                                    width: "100%",
                                    //objectFit: "contain"
                                }}
                                src={obj} />
                        </Col>
                    })
                }
            </Slider>
        )

    }
    const renderAboutUsSection = () => {
        const carouselSettings = {
            ...mainCarouselSettings,
            dots: false,
        }
        return <Col md={12} style={{ marginBottom: 50,paddingBottom: 25 }}>
            <Container>
                {/* <SectionHeading windowWidth={windowWidth}>About Us</SectionHeading> */}
                <div style={{

                    fontFamily: 'DM sans',
                    fontSize: windowWidth > 768 ? '3.75rem' : 40,
                    transition: 'font-size .5s',
                    textAlign: 'center',

                    marginBottom: 50,
                    fontWeight: 700,
                    color: Palette.BLUE2023,
                }}>
                    About Us
                </div>
                <Row>

                    <Col
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                        lg={6} sm={12}>
                        <div style={{
                            fontSize: "2em",
                            color: Palette.BLUE2023
                        }}>
                            Welcome to Jaktent!
                        </div>
                        <div style={{
                            fontFamily: 'DM Sans',
                            color: Palette.BLUE2023
                        }}>
                            <br />
                            Jakarta Content Week (Jaktent) is an international creative festival pioneered
                            and organized by Yayasan Tujuhbelasribu Pulau Imaji (17000 Islands of Imagination
                            Foundation) in collaboration with Frankfurter Buchmesse (FBF). Jaktent brings together
                            major players from various creative industries sectors - publishing, cuisine, fashion,
                            film, design, games, crafts, cosplay, digital startups, and other media.
                        </div>

                        <CircularButton
                            style={{ width: 100, background: Palette.ORANGE2023 }}
                            isLink
                            to={'/about'}
                        >
                            MORE <FaArrowRight />
                        </CircularButton>
                    </Col>
                    <Col lg={6} sm={12} style={{ marginTop: 10, marginBottom: 10, position: 'relative' }}>
                        <Slider {...carouselSettings} style={{ alignItems: "center" }}>
                            {aboutUsImages.map(photo => {
                                return <img src={photo} />
                            })}
                        </Slider>
                        <div style={{ background: Palette.YELLOW, width: 300, height: 50, position: "absolute", bottom: -20, left: -20 }}></div>
                    </Col>
                    <Col lg={1} sm={12}></Col>
                </Row>
            </Container>
        </Col>
    }

    const renderSessionSection = () => {
        return <Container fluid>
            <Row>
                <Col
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        // background: "white"
                    }}
                    lg={6} sm={12}>
                    <Col md={12}>
                        <div style={{ padding: '2rem' }}>
                            <div style={{
                                fontSize: "2em",
                                color: Palette.BLUE2023
                            }}>
                                Explore the Fair!
                            </div>
                            <div style={{
                                paddingRight: '3rem',
                                fontFamily: 'DM Sans',
                                color: Palette.BLUE2023
                            }}>
                                {/* <br /> */}
                                {/* Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. */}
                            </div>
                            <CircularButton
                                style={{ maxWidth: '50%', background: Palette.ORANGE2023 }}
                                isLink
                                to={'/programs'}
                            >
                                EXPLORE ALL <FaArrowRight />
                            </CircularButton>
                        </div>
                    </Col>



                </Col>
                <Col lg={6} sm={12} style={{ marginTop: 10, background: Palette.BLUE2023, paddingRight: 10, paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}>
                    <div className="home-session-container">
                        <div className="home-session-list" style={{ display: "flex", justifyContent: "flex-end" }}>
                            {/* <Pagination>
                            <Pagination.Prev />
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item disabled>{3}</Pagination.Item>
                        </Pagination> */}

                            <ReactPaginate
                                nextLabel={<BsChevronRight />}
                                previousLabel={<BsChevronLeft />}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />

                        </div>
                        <div style={{
                            position: 'relative',

                        }}>
                            {renderSession(currentPageData)}
                        </div>
                    </div>

                </Col>

            </Row>
        </Container>

    }
    const renderSpeakerSection = () => {

        let speakerColors = [Palette.YELLOW2023, Palette.GREEN2023, Palette.ORANGE2023, Palette.RED]

        return <Container style={{ margin: 0, padding: 0 }} fluid>
            <div style={{
                fontFamily: 'DM sans',
                fontSize: windowWidth > 768 ? '3.75rem' : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                // marginTop: 50,
                // marginBottom: 50,
                padding: '5rem',
                background: Palette.YELLOW2023,
                fontWeight: 700,
                color: Palette.BLUE2023,
            }}>
                Speakers
            </div>
            {
                isSpeakerDataFetched ?
                    <>
                        <Slider className="slick-home" {...speakerCarouselSettings} style={{ marginTop: '-20px' }}>
                            {
                                speakers.map((obj, key) => {
                                    return <SpeakerCard
                                        speaker={obj}
                                        noPadding
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        xl={2}
                                        isMobile={windowWidth < 768}
                                        height="500px"
                                        activeColor={speakerColors[key % speakerColors.length] + "90"}
                                    />
                                })
                            }
                        </Slider>


                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '5rem',
                            background: Palette.YELLOW2023,
                            fontWeight: 700,
                            color: Palette.BLUE2023,
                            marginTop: '-20px'
                        }}>
                            <CircularButton
                                isLink
                                to={'/speakers'}
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                ALL SPEAKERS
                                <FiArrowRight style={{ marginLeft: 6 }} size={20} />
                            </CircularButton>
                        </div>
                    </> :
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40, marginTop: 40 }}>
                        <LoadingProgress />
                    </div>
            }
        </Container>


    }
    const renderCuratorSection = () => {
        let carouselSettings = {
            ...speakerCarouselSettings,
            // adaptiveHeight: true
        }

        return <Col md={12} style={{ padding: 0, marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25 }}>
            {/*<SectionHeading windowWidth={windowWidth} style={{}}>Curators</SectionHeading> */}
            <div style={{
                fontFamily: 'DM sans',
                fontSize: windowWidth > 768 ? '3.75rem' : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 50,
                fontWeight: 700,
                color: Palette.BLUE2023,
            }}>
                Curators
            </div>
            <Slider {...carouselSettings}>
                {curators.map(obj => {
                    return (
                        // <div>
                        //     <div style={{
                        //         height: "400px",
                        //         backgroundImage: `url(${curator.image})`,
                        //         backgroundSize: "cover",
                        //         "-moz-filter": `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
                        //         "-o-filter": `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
                        //         "-webkit-filter": `grayscale(100%)`,
                        //     }}>
                        //     </div>
                        // </div>
                        <Col style={{
                            textAlign: "center",
                            marginTop: "0.5em",
                            marginBottom: "0.5em"
                        }} lg={4} md={6}>

                            <div style={{
                                //background: "white",
                                paddingTop: 50,
                                paddingBottom: 50,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingLeft: "10%",
                                paddingRight: "10%"
                            }}>

                                <img
                                    style={{
                                        maxWidth: 200,
                                        aspectRatio: 1,
                                        borderRadius: 250,
                                        objectFit: "cover",
                                        objectPosition: "center"
                                    }}
                                    src={obj.image}
                                />

                                <div style={{
                                    fontWeight: "bold",
                                    fontSize: "2em",
                                    marginTop: 30,
                                    textAlign: "center",
                                    color: Palette.BLUE2023,
                                }}>
                                    {obj.name}
                                </div>
                                <div style={{
                                    fontSize: "1em",
                                    color: Palette.BLUE2023,
                                }}>
                                    {obj.affiliation ? obj.affiliation : (<br />)}
                                </div>
                                <br />
                                <p style={{
                                    color: "gray",
                                    textAlign: "center",
                                    color: Palette.BLUE2023,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 4,
                                    lineClamp: 4,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden"
                                }}>
                                    
                                    {obj.bio}
                                </p>

                            </div>
                        </Col>
                    )
                })}
            </Slider>


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularButton
                    isLink
                    to={'/curators'}
                    style={{ display: "flex", flexDirection: "row" }}
                >
                    ALL CURATORS
                    <FiArrowRight style={{ marginLeft: 6 }} size={20} />
                </CircularButton>
            </div>
        </Col>
    }
    const renderAgendaSection = () => {
        return <Col md={12} style={{ marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25, padding: 0 }}>
            <Container fluid>
                <SectionHeading windowWidth={windowWidth}>Agenda</SectionHeading>
                <Row>
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        md={12}
                    >
                        <AgendaComponentV2 />
                    </Col>
                </Row>
            </Container>

        </Col>
    }

    const renderMapsSection = () => {
        return <Container fluid>
            <Row>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",

                    }}
                    lg={6} sm={12}>
                    <Col md={12}>
                        <div style={{ padding: '2.5rem' }}>
                            <div style={{
                                fontSize: "2em",
                                color: Palette.BLUE2023
                            }}>
                                Maps
                            </div>
                            <div style={{
                                paddingRight: '3rem',
                                fontFamily: 'DM Sans',
                                color: Palette.BLUE2023
                            }}>
                                <br />
                                Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                            </div>
                            <CircularButton
                                style={{ maxWidth: 150, background: Palette.ORANGE2023 }}
                                isLink
                                to={'/maps'}
                            >
                                View Maps <FaArrowRight />
                            </CircularButton>
                        </div>
                    </Col>



                </Col>
                <Col lg={6} sm={12} style={{ padding: '0' }}>
                    <Col md={12}>
                        <img
                            style={{
                                width: "100%",
                                //objectFit: "contain"
                            }}
                            src={imgmaps} />
                    </Col>
                </Col>

            </Row>
        </Container>

    }

    const renderMediaPartnerSection = () => {
        return <Col md={12} style={{ marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25 }}>
            <Container style={{ maxWidth: 1200 }}>
                {/* <SectionHeading windowWidth={windowWidth}>Media Partners</SectionHeading> */}
                <div style={{
                    fontFamily: 'DM sans',
                    fontSize: windowWidth > 768 ? '3.75rem' : 40,
                    transition: 'font-size .5s',
                    textAlign: 'center',
                    //marginTop: 50,
                    marginBottom: 50,
                    fontWeight: 700,
                    color: Palette.BLUE2023,
                }}>
                    Partners
                </div>
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                    {partnerImages.map((obj) => {
                        return <Col
                            style={{
                                paddingTop: 20, paddingBottom: 20,
                                paddingLeft: 40, paddingRight: 40,
                            }}
                            md={3} xs={6}>
                            <img
                                style={{
                                    objectFit: "contain",
                                    width: "100%"
                                }}
                                src={obj} />
                        </Col>
                    })}
                </Row>
            </Container>

        </Col>
    }

    const renderSponsorSection = () => {
        return <Col md={12} style={{ marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25 }}>
            <Container style={{ maxWidth: 1200 }}>
                {/* <SectionHeading windowWidth={windowWidth}>Sponsors</SectionHeading> */}
                <div style={{
                    fontFamily: 'DM sans',
                    fontSize: windowWidth > 768 ? '3.75rem' : 40,
                    transition: 'font-size .5s',
                    textAlign: 'center',
                    //marginTop: 50,
                    marginBottom: 50,
                    fontWeight: 700,
                    color: Palette.BLUE2023,
                }}>
                    Sponsors
                </div>
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                    {partnerImages.map((obj) => {
                        return <Col
                            style={{
                                paddingTop: 20, paddingBottom: 20,
                                paddingLeft: 40, paddingRight: 40,
                            }}
                            md={3} xs={6}>
                            <img
                                style={{
                                    objectFit: "contain",
                                    width: "100%"
                                }}
                                src={obj} />
                        </Col>
                    })}
                </Row>
                {/*<Row style={{ alignItems: "center", justifyContent: "center" }}>
                    {partnerImages.map((sponsorLogo) => {
                        return
                        <Col
                            style={{
                                paddingTop: 20, paddingBottom: 20,
                                paddingLeft: 40, paddingRight: 40,
                            }}
                            md={3} xs={6}>
                            <img
                                style={{
                                    objectFit: "contain",
                                    width: "100%"
                                }}
                                src={sponsorLogo} />
                        </Col>
                    })}  
                </Row> */}
            </Container>

        </Col>
    }

    return (
        <AppContainer removeTopPadding title={"Home"}>
            <Row style={{
                width: "100%",
                padding: 0,
                margin: 0,
            }}>
                <div style={{ padding: 0, marginTop: '3.25rem', backgroundColor: Palette.YELLOW2023, display: "flex", justifyContent: 'center' }}>
                    <img
                        style={{
                            width: "100%",
                            //objectFit: "contain"
                        }}
                        src={hero} />
                </div>

                {/* {renderCarouselSection()} */}
                {renderAboutUsSection()}
                {renderSessionSection()}
                {/* {renderSpeakerSection()} */}
                {renderCuratorSection()}
                {/* {renderAgendaSection()} */}
                {/*{renderSponsorSection()}*/}
                {/* {renderMapsSection()} */}
                {/* {renderMediaPartnerSection()}
                {renderSponsorSection()} */}
            </Row>
        </AppContainer>
    )
}