import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Palette from "../utils/Palette";
import Utils from "../utils/Utils";
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import SearchBar from "./SearchBar";

export default function Sidebar(props) {

    const {
        isSidebarOpen,
        setSidebarOpen,
        windowWidth,
        headerMenu,
        setHeaderMenu,
        defaultHeaderMenu,
        setIsQRModalOpen,
    } = props;

    return (
        <div style={{
            overflow: 'hidden',
            position: 'fixed',
            maxWidth: isSidebarOpen ? '100%' : 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#f7f7f7',
            transition: 'max-width .4s',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '30px 20px 20px 20px',
                marginBottom: 20,
                alignItems: 'flex-start'
            }}>
                <Link to={'/home'} onClick={() => setSidebarOpen(false)}>
                    <img src={'/new/logo_fc2.png'} style={{ maxWidth: 200 }} alt='logo' />
                </Link>

                <div style={{ flex: 1 }} />

                <div
                    onClick={() => setSidebarOpen(false)}
                    style={{
                        border: '1px solid rgba(0,0,0,.5)',
                        padding: '7px 14px 11px 14px',
                        marginRight: windowWidth > 770 ? 30 : 0,
                        cursor: 'pointer',
                        borderRadius: 3,
                    }}>
                    <AiOutlineClose size={15} />
                </div>
            </div>

            {localStorage.getItem("name") !== null &&
                <Accordion flush>
                    <AccordionItem eventKey="0" flush>
                        <AccordionHeader style={{ backgroundColor: 'rgb(253, 253, 243)' }}>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: 700,
                                    letterSpacing: .2,
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                    padding: "20px 0"
                                }}>
                                Hi, {localStorage.getItem("name")}!
                            </div>
                        </AccordionHeader>
                        <AccordionBody>
                            <Link to={'/my-events'} onClick={() => setSidebarOpen(false)} style={{ textDecoration: 'none', color: "black" }}>
                                <div
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        letterSpacing: .2,
                                        userSelect: 'none',
                                        cursor: 'pointer',
                                        marginBottom: 20
                                    }}>
                                    My Events
                                </div>
                            </Link>
                            <Link to={'/edit-profile'} onClick={() => setSidebarOpen(false)} style={{ textDecoration: 'none', color: "black" }}>
                                <div
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        letterSpacing: .2,
                                        userSelect: 'none',
                                        cursor: 'pointer',
                                        marginBottom: 20
                                    }}>
                                    Edit Profile
                                </div>
                            </Link>
                            <div style={{
                                fontSize: 16,
                                fontWeight: 700,
                                letterSpacing: .2,
                                userSelect: 'none',
                                cursor: 'pointer',
                                marginBottom: 20
                            }} onClick={() => {
                                setSidebarOpen(false);
                                setIsQRModalOpen(true);
                            }}>
                                Show QR Code
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            }

            {
                headerMenu.map((menu, idx) => (
                    <Link to={menu.route} style={{ textDecorationLine: 'none' }} onClick={() => setSidebarOpen(false)}>
                        <div
                            onMouseOver={() => {
                                const headerMenuTemp = [...defaultHeaderMenu];

                                headerMenuTemp[idx].hovered = true;

                                setHeaderMenu(headerMenuTemp)
                            }}
                            onMouseLeave={() => {
                                const headerMenuTemp = [...defaultHeaderMenu];

                                setHeaderMenu(headerMenuTemp)
                            }}
                            style={{
                                fontSize: 16,
                                fontWeight: 700,
                                padding: '0px 17px 0px 17px',
                                letterSpacing: .2,
                                userSelect: 'none',
                                cursor: 'pointer',
                                color: menu.selected || menu.hovered ? Palette.PINK : '#000000',
                                marginBottom: 20
                            }}>
                            {menu.title.toUpperCase()}
                        </div>
                    </Link>
                ))

            }
            <div className="p-1">
                <SearchBar showSerch isMobileView />
            </div>
            <div style={{ flex: 1 }} />

            {localStorage.getItem("name") === null ?
                <Link to={'/login'} style={{ textDecorationLine: 'none' }} onClick={() => setSidebarOpen(false)}>
                    <div style={{
                        fontWeight: 700,
                        backgroundColor: Palette.YELLOW,
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: 'black',
                        fontSize: 16,
                        padding: '15px 45px 15px 45px',
                        textAlign: 'center',
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 30,
                        borderRadius: 200
                    }}>
                        LOGIN
                    </div>
                </Link> :
                <div style={{
                    fontWeight: 700,
                    backgroundColor: Palette.YELLOW,
                    cursor: 'pointer',
                    userSelect: 'none',
                    color: 'black',
                    fontSize: 16,
                    padding: '15px 45px 15px 45px',
                    textAlign: 'center',
                    marginLeft: 15,
                    marginRight: 15,
                    marginBottom: 30,
                    borderRadius: 200
                }} onClick={() => Utils.logout()}>
                    LOGOUT
                </div>
            }
        </div>
    )
}
