import about1 from '../assets/about/_MG_5581.jpg';
import about2 from '../assets/about/_MG_5965.jpg';
import about3 from '../assets/about/_MG_6455.jpg';
import about4 from '../assets/about/_MG_7240.jpg';
import about5 from '../assets/about/AdePutri.png';
import about6 from '../assets/about/DavidWallace02(1).jpg';
import about7 from '../assets/about/DSCF2507.jpg';
import about8 from '../assets/about/DSCF2827-(1).jpg';
import about9 from '../assets/about/IMG_0241.jpg';
import about10 from '../assets/about/IMG_8917.jpg';
import about11 from '../assets/about/IMG_9415.jpg';
import about12 from '../assets/about/JoeSabia.jpg';
import about13 from '../assets/about/JuanPabloVillalobos.png';
import about14 from '../assets/about/Kimsaebyul.png';
import about15 from '../assets/about/MattHaig.jpg';
import about16 from '../assets/about/NasDaily.jpg';
import about17 from '../assets/about/SophieKinsella.jpg';
import about18 from '../assets/about/WilliamWongso_AdePutri.jpg';


const aboutUsImages = [
    about1,
    about2,
    about3,
    about4,
    about5,
    about6,
    about7,
    about8,
    about9,
    about10,
    about11,
    about12,
    about13,
    about14,
    about15,
    about16,
    about17,
    about18,
];

export default aboutUsImages;