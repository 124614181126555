import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Grid, Placeholder } from "react-bootstrap";
import CircularButton from "../components/CircularButton";
import Palette from "../utils/Palette";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import placeholder from '../assets/2023/placeholder1.png'
import { Link, useNavigate, useParams } from "react-router-dom";
import ContentsDAO from "../dao/ContentsDAO";
import moment from "moment";
import parse from 'html-react-parser';
import LoadingProgress from "../components/LoadingProgress";

export default function NewsDetail() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [content, setContent] = useState()

    const navigate = useNavigate();
    const { id } = useParams()

    const handleCounter = async (url) => {
        let res = await ContentsDAO.counter(id)
        window.open(url, '_blank');
    }

    const Style = {
        small: {
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'stretch',
            fontFamily: 'DM sans',
            fontSize: '0.9rem',
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: '1.4rem',
            color: Palette.BLUE2023,
        },
        big: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            fontFamily: 'DM sans',
            fontSize: '1.5rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '2rem',
            color: Palette.BLUE2023,
        },
    }

    const fetchData = async () => {
        let r = await ContentsDAO.getById(id)
        console.log('isi r', r)
        let time = moment(r.created_at).from()
        let wordsArray = r.content.split(/\s+/)
        let l = wordsArray.length
        let duration
        if (l == 1) duration = "1 min read"
        else duration = Math.round(l / 200) + " mins read"
        setContent({ ...r, time: time, duration: duration })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"News"}>
            <Container>
                <Row>
                    <div
                        style={{
                            fontFamily: 'DM sans',
                            fontSize: /* windowWidth > 768 ? */ '1rem' /* : 40 */,
                            transition: 'font-size .5s',
                            textAlign: 'left',
                            marginTop: '20px',
                            marginBottom: '20px',
                            //marginBottom: 50,
                            fontWeight: 700,
                            color: Palette.BLUE2023,
                            display: 'flex',
                            alignItems: "center",
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate('/news')}
                    >
                        <FiArrowLeft style={{ marginRight: 10 }} size={'1rem'} />BACK
                    </div>
                </Row>
            </Container>

            {!content ? <div className="d-flex mb-3 w-100" style={{ justifyContent: 'center', alignItems: 'center', height: '50vh' }}><div style={{ position: 'relative', bottom: 50 }}><LoadingProgress /></div></div> : (
                <div style={{ /* maxWidth: 900, */ marginBottom: 50, }}>


                    <div
                        style={{
                            width: "100vw",
                            padding: 0,
                            margin: 0,
                            height: 'auto',
                            objectFit: 'contain',
                        }}
                    >
                        <img src={content.banner_url} style={{ width: '100%', objectFit: 'contain' }} />
                    </div>

                    <Container style={{ marginTop: '2rem', gap: '1rem', display: "flex", flexDirection: 'column' }}>
                        <Row style={Style.big}>
                            <div>
                                {content?.title}
                            </div>
                        </Row>
                        <div style={{ ...Style.small, alignItems: "center" }}>
                            <div>{content?.time}</div>
                            <div style={{ width: 5, height: 5, backgroundColor: Palette.BLUE2023, borderRadius: 3, margin: '0 0.5rem' }} />
                            <div>{content?.duration}</div>
                        </div>
                        <div style={{ ...Style.small, flexDirection: 'column', gap: '1rem', color: "black" }}>
                            {parse(content.content)}
                        </div>
                        {
                            content?.origin_url && content?.origin_title ?
                                <Row>
                                    <Col md={12}>
                                        Read more : <a href={content?.origin_url} style={{ width: 50 }} onClick={() => {
                                            handleCounter(content?.origin_url)
                                        }} target="_blank">{content?.origin_title}</a>
                                    </Col>
                                </Row>
                                : ''
                        }

                    </Container>
                </div>

            )}
        </AppContainer>
    )
}