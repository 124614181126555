import { Card, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment"
import LoadingProgress from "./LoadingProgress";
import Palette from "../utils/Palette";

export default function ScheduleCard({ session, windowWidth, md, customClassCard, customStyleCard }) {

    const { id, name, date, start_time, end_time, speakers, image_url, location, style } = session;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [cardHeight, setCardHeight] = useState(0)
    useEffect(() => {
        if (image_url !== null || image_url !== '')
            setImageLoaded(true)
    }, [image_url])

    useEffect(() => {

    }, [cardHeight])

    return <Col className={customClassCard ?? 'p-4'} md={md ? md : 3} style={{ ...customStyleCard, }} >
        <Link style={{
            // display: 'flex',x
            color: 'white',
            textDecorationLine: 'none',
            flexDirection: windowWidth < 410 ? 'column' : 'row'
        }} to={'/event/' + id}>
            <Card>
                {
                    image_url &&
                    <Card.Img style={{
                        objectFit: 'contain',
                    }} thumbnail variant="top" src={image_url === null || image_url === "" ? require('../assets/temp_banner/TEMPORARY-POSTER.jpg') : image_url} />
                }

                <Card.Body style={{ padding: 0, height : 300 }}>
                    <div 
                        className="d-flex align-items-center justify-content-center flex-column"
                     style={{ background: Palette.BLUE2023, textAlign: 'center', color: '#FFF', height : "100%" }}>
                        <div
                            style={{ paddingTop: 0, paddingBottom : 0, paddingLeft : 20, paddingRight : 20, fontWeight: '600', fontSize: windowWidth > 768 ? 20 : 16, transition: 'font-size .5s'}}>
                            {name}
                        </div>
                        <div style={{height : 20}}>

                        </div>
                        <div className={"d-flex align-items-center justify-content-center flex-column"} style={{ minHeight: 30 }}>
                            <div style={{ fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s' }}>
                                {(speakers && speakers.length > 0) &&
                                    speakers.map((obj, key) => {
                                        return <>{obj.name}{key < speakers.length - 1 ? " | " : ""}</>
                                    })
                                }
                            </div>

                            <div style={{ fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s' }}>
                                {moment(start_time).format("DD MMM YYYY")}
                            </div>

                            <div style={{ fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s' }}>
                                {moment(start_time).format("HH:mm")} - {moment(end_time).format("HH:mm")} WIB
                            </div>
                            
                            <div style={{height : 20}}>
                                
                            </div>
                            <div style={{ fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s' }}>
                                {location}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Link>

    </ Col>
}
