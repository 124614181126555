import API from "../utils/API";

export default class ContentsDAO {
  static getAll = async () => {
    return await API.request('v1/contents', 'GET');
  }

  static search = async (q) => {
    return await API.request(`v1/content/search/${q}`, 'GET');
  }

  static getById = async (id) => {
    return await API.request('v1/content/' + id, 'GET');
  }

  static counter = async (id) => {
    return await API.request('v1/content/counter-origin/' + id, 'PUT');
  }

}