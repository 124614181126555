import { useEffect, useRef, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Palette from "../utils/Palette";

export default function SearchBar(props) {
    const navigate = useNavigate()
    const onEnter = async (e) => {
        if (e.key === "Enter" && keyword !== "") {
            navigate(`/search/${keyword}`)
            navigate(0)
        }
    }
    const [keyword, setKeyword] = useState("")
    const { toogleSearch, showSearch, isMobileView } = props
    const searchRef = useRef(null);
    useEffect(() => {
        if (showSearch) {
            searchRef.current.focus();
        }
    }, [showSearch]);
    return (
        <>
            {
                isMobileView ? <div style={{ position: 'relative' }}>
                    <AiOutlineSearch size={20} style={{
                        position: 'absolute',
                        left: 25,
                        top: 0,
                        bottom: 0,
                        color: '#046E86',
                        margin: 'auto 0'
                    }} />

                    <input autoComplete="off" ref={searchRef} type="text" name="search" placeholder="Search programs, speakers, agenda, etc" required="" style={{
                        fontSize: 13,
                        borderRadius: '40px',
                        border: '2px solid #046E86',
                        background: '#FFF',
                        padding: '10px 5px 10px 50px',
                        width: '100%',
                    }} value={keyword} onKeyDown={onEnter} onChange={(e) => setKeyword(e.target.value)} />

                </div> :
                    <Modal onHide={() => {
                        toogleSearch(!showSearch)
                    }} backdrop size="lg" className="modal-search" show={showSearch} aria-labelledby="contained-modal-title-vcenter">
                        <Modal.Header style={{ border: 'none' }}>
                            <Row style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => {
                                    toogleSearch(!showSearch)
                                }} style={{ fontSize: '1.5em', fontWeight: 'bolder', color: Palette.ORANGE2023 }} variant="link">
                                    <AiOutlineClose />
                                </Button>
                            </Row>
                        </Modal.Header>
                        <Modal.Body className="grid-example">
                            <div className="search-box-dialog show">
                                <div style={{ position: 'relative' }}>
                                    <AiOutlineSearch size={20} style={{
                                        position: 'absolute',
                                        left: 25,
                                        top: 0,
                                        bottom: 0,
                                        color: '#046E86',
                                        margin: 'auto 0'
                                    }} />

                                    <input autoComplete="off" ref={searchRef} type="text" name="search" placeholder="Search programs, speakers, agenda, etc" required="" style={{
                                        fontSize: 20,
                                        borderRadius: '40px',
                                        border: '2px solid #046E86',
                                        background: '#FFF',
                                        padding: '15px 10px 15px 70px',
                                        width: '100%',
                                    }} value={keyword} onKeyDown={onEnter} onChange={(e) => setKeyword(e.target.value)} />

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            }

        </>


    )
}