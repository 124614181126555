import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "../components/AppContainer";
import LoadingProgress from "../components/LoadingProgress";
import Palette from "../utils/Palette";
import SessionsList from "../components/SessionsList";
import ContentsDAO from "../dao/ContentsDAO";
import SessionDAO from "../dao/SessionDAO";
import SearchDAO from "../dao/SearchDAO";
import SpeakerCard from "../components/SpeakerCard";
export default function SearchPage() {
    const { keyword } = useParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loadingNews, setLoadingNews] = useState(true)
    const [newsContent, setNewsContent] = useState([])
    const [sessions, setSessions] = useState([])
    const [bannerLoad, setBannerLoad] = useState()
    const [loading, setLoading] = useState(false)
    const [speakers, setSpeakers] = useState([])
    const [cardNewsHeight, setCardNewsHeight] = useState(0)
    const initializeSearch = async () => {
        setLoading(true)
        let result = await SearchDAO.search(keyword)

        setNewsContent(result?.content)
        setSessions(result?.session)
        setSpeakers(result?.speaker)
        setLoading(false)
    }




    useEffect(() => {
        initializeSearch()
    }, [])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    let speakerColors = [Palette.YELLOW2023, Palette.GREEN2023, Palette.ORANGE2023, Palette.RED]

    const navigate = useNavigate()


    const Style = {
        small: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            fontFamily: 'DM sans',
            fontSize: '0.9rem',
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: '1.4rem',
        },
        big: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            fontFamily: 'DM sans',
            fontSize: '1.3rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '2rem',
        },
    }

    const renderPrograms = () => {
        return (
            <>
                <Container>
                    {
                        !loading && sessions?.length > 0 &&
                        <div style={{
                            height: 'auto',
                            transition: 'opacity 3s ease',

                        }}>
                            <SessionsList
                                customCardSize={3}
                                customClassCard={'card-search-padding'}
                                sessions={sessions}
                                windowWidth={windowWidth}
                                errorMessage="No sessions found in this category." />
                        </div>
                    }
                    {
                        !loading && sessions?.length == 0 && <div style={{
                            marginTop: 15,
                            fontSize: windowWidth > 768 ? 16 : 14,
                            transition: 'font-size .5s',
                            textAlign: "center"
                        }}>No Result Found</div>
                    }

                </Container>
            </>
        )
    }

    const renderSpeaker = () => {
        return (
            <>
                <Container>
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em"
                    }}
                    >

                        <>
                            {speakers?.map((obj, key) => {
                                return <Col xl={3} md={4} sm={6}>
                                    <SpeakerCard
                                        speaker={obj}
                                        isMobile={windowWidth < 1000}
                                        height="350px"
                                        activeColor={speakerColors[key % speakerColors.length] + "90"}
                                    />
                                </Col>
                            })}
                            {
                                !loading && speakers?.length == 0 && <div style={{
                                    marginTop: 15,
                                    fontSize: windowWidth > 768 ? 16 : 14,
                                    transition: 'font-size .5s',
                                    textAlign: "center"
                                }}>No Result Found</div>
                            }
                        </>

                    </Row>
                </Container>
            </>
        )

    }

    const renderNews = () => {
        return (
            <>

                <Container>
                    <Row style={{ margin: 0, padding: 0, display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                        {[...newsContent].map((el, index) => {
                            let time = moment(el.created_at).from()
                            let wordsArray = el.content.split(/\s+/)
                            let desc = wordsArray.slice(0, 11).join(" ");
                            // Let's strip all the html tags
                            let div = document.createElement("div")
                            div.innerHTML = desc
                            desc = div.textContent || div.innerText || ''
                            // Thank you
                            let l = wordsArray.length
                            let duration
                            if (l == 1) duration = "1 min read"
                            else duration = Math.round(l / 200) + " mins read"
                            return (
                                <Col
                                    ref={(ref) => {
                                        if (ref) {
                                            if (cardNewsHeight === 0 || ref.clientHeight > cardNewsHeight) {
                                                setCardNewsHeight(ref.clientHeight)
                                            }
                                        }
                                    }}
                                    xl={3} md={6} sm={12} style={{ width: '18rem', margin: 0, padding: 0, marginBottom: '2rem', cursor: 'pointer' }} onClick={() => navigate('/news/' + el.id)}>
                                    <Card
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '1rem',
                                            flex: '1 0 0',
                                            color: Palette.BLUE2023,
                                            width: '18rem',
                                            height: 'auto',
                                            border: 0,
                                            color: '#ffffff',
                                            backgroundColor: Palette.GREEN2023,

                                        }}>
                                        <div style={{ width: '18rem', height: cardNewsHeight / 2.2, background: `url(${el.thumbnail_url})`, backgroundSize: 'cover', borderRadius: '0.5rem' }} />
                                        <div className="p-2" style={Object.assign({}, Style.small, {
                                            height: 20
                                        })}>
                                            {time}
                                        </div>
                                        <div

                                            className="p-2" style={Object.assign({}, Style.big, {
                                                height: cardNewsHeight * 0.17
                                            })}>
                                            {el.title}
                                        </div>
                                        <div className="p-2" style={Object.assign({}, Style.small, {
                                            height: cardNewsHeight * 0.15
                                        })}>
                                            {desc}
                                        </div>
                                        <div className="p-2" style={Style.small}>
                                            {duration}
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}

                    </Row>

                    {
                        !loading && newsContent?.length == 0 && <div style={{
                            marginTop: 15,
                            fontSize: windowWidth > 768 ? 16 : 14,
                            transition: 'font-size .5s',
                            textAlign: "center"
                        }}>No Result Found</div>
                    }



                </Container>
            </>
        )
    }

    const tabBadge = (count) => (
        <>
            <Badge bg="" style={{
                backgroundColor: Palette.ORANGE2023,
                color: 'white',
            }}>{count}</Badge>{" "}
        </>
    )
    return (
        <>
            <AppContainer title={"Search"}>
                <Container>
                    <Row className={`mt-5 mb-5`}>
                        <div className="search-result">
                            <strong>
                                Showing Result For : {keyword}
                            </strong>

                        </div>
                    </Row>
                </Container>
                <Container>
                    {
                        loading ? <div className="d-flex mb-3 w-100" style={{ justifyContent: 'center', alignItems: 'center', height: '50vh' }}><div style={{ position: 'relative', bottom: 50 }}><LoadingProgress /></div></div> :
                            <>
                                {
                                    !loading && (newsContent?.length !== 0 || speakers?.length !== 0 || sessions?.length !== 0) ?
                                        <Tabs
                                            style={{ width: "100%", padding: '1rem' }}
                                            className={`tabs-agenda mb-3`}
                                            defaultActiveKey={newsContent?.length > 0 ? 'news' : sessions?.length > 0 ? 'programs' : 'speakers'}
                                        >
                                            {
                                                newsContent?.length > 0 &&
                                                <Tab eventKey="news" title={
                                                    <span style={{ color: Palette.BLUE2023 }}>
                                                        News {tabBadge(newsContent?.length)}
                                                    </span>
                                                }
                                                >
                                                    <div className="mb-4">
                                                        {renderNews()}
                                                    </div>

                                                </Tab>
                                            }
                                            {
                                                sessions?.length > 0 &&
                                                <Tab eventKey="programs" title={
                                                    <span style={{ color: Palette.BLUE2023 }}>
                                                        Programs {tabBadge(sessions?.length)}
                                                    </span>
                                                }>

                                                    <div className="mb-4">
                                                        {renderPrograms()}
                                                    </div>

                                                </Tab>
                                            }
                                            {
                                                speakers?.length > 0 && <Tab eventKey="speakers" title={
                                                    <span style={{ color: Palette.BLUE2023 }}>
                                                        Speaker {tabBadge(speakers?.length)}
                                                    </span>
                                                }>
                                                    <div className="mb-4">
                                                        {renderSpeaker()}
                                                    </div>

                                                </Tab>
                                            }

                                        </Tabs>
                                        : ''

                                }

                            </>
                    }
                </Container>

                {
                    !loading && (newsContent?.length == 0 && speakers?.length == 0 && sessions?.length == 0) && <div style={{
                        marginTop: 50,
                        marginBottom: 50,
                        fontSize: windowWidth > 768 ? 16 : 14,
                        transition: 'font-size .5s',
                        textAlign: "center"
                    }}>No Result Found</div>
                }


            </AppContainer>
        </>
    )
}