import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SpeakerCard from "../components/SpeakerCard";
import SpeakerDAO from "../dao/SpeakerDAO";
import LoadingProgress from "../components/LoadingProgress";
import Slider from "react-slick";
import speaker1 from "../assets/temp_banner/speaker/team_4.jpg";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import CuratorDAO from "../dao/CuratorDAO";
import _ from "lodash";
import partnerImages from "../misc/PartnerImages";
import Palette from "../utils/Palette";

export default function Curators() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [speakers, setSpeakers] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [curators, setCurators] = useState({});
    const [groups, setGroups] = useState([]);

    const getCurators = async () => {
        try {
            let result1 = await CuratorDAO.getAll();
            let result2 = await CuratorDAO.getGroups();
            result2 = [{
                id: 0, name: "null"
            }, ...result2]
            let groupedBy = _.groupBy(result1, (obj) => obj.affiliation)

            await setCurators(groupedBy);
            await setGroups(result2);
            console.log("Groups: ", result2)
            console.log("groupedby: ", groupedBy)
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        getCurators();
    }, []);

    const renderCurator = (obj) => {
        return (
            <Col style={{

                textAlign: "center",
                marginTop: "0.5em",
                marginBottom: "0.5em"
            }} lg={6} md={6}
            >

                <div style={{
                    background: Palette.YELLOW2023,
                    paddingTop: 50,
                    paddingBottom: 50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "10%",
                    paddingRight: "10%"
                }}
                >

                    <img
                        style={{
                            maxWidth: 200,
                            aspectRatio: 1,
                            borderRadius: 250,
                            objectFit: "cover",
                            objectPosition: "center"
                        }}
                        src={obj.image}
                    />

                    <div style={{
                        fontWeight: "bold",
                        fontSize: "2em",
                        marginTop: 30,
                        color: Palette.BLUE2023,
                    }}>
                        {obj.name}
                    </div>
                    <div style={{
                        fontSize: "1em",
                    }}>
                        {obj.affiliation}
                    </div>

                    <p style={{
                        color: "gray",
                        color: Palette.BLUE2023,
                    }}>
                        <br />
                        {obj.bio}
                    </p>

                </div>
            </Col>
        )
    }

    const renderLogos = () => {
        return <Col xs={12} sm={10} style={{ marginTop: 15, marginBottom: 50, paddingTop: 25, paddingBottom: 25 }}>
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                    {partnerImages.map((obj) => {
                        return <Col
                            style={{
                                paddingTop: 20, paddingBottom: 20,
                                paddingLeft: 40, paddingRight: 40,
                            }}
                            md={2} xs={4}>
                            <img
                                style={{
                                    objectFit: "contain",
                                    width: "100%"
                                }}
                                src={obj} />
                        </Col>
                    })}
                </Row>
        </Col>
    }

    return (
        <AppContainer title={"Curators"}>
            <div style={{
                fontFamily: 'DM sans',
                fontSize: windowWidth > 768 ? '2.5rem' : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 50,
                fontWeight: 700,
                color: Palette.BLUE2023,
            }}>
                Curators
            </div>

            <Container style={{
                maxWidth: 1400
            }}>
                {groups.map((group, key) => (<>
                    {group.name !== "null" &&
                        <Row style={{
                            width: "100%",
                            padding: 0,
                            margin: 0,

                            justifyContent: "center"
                        }}
                        >
                            <Col xs={12} sm={8} style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "20px 0" }}>
                                <img
                                    style={{
                                        maxWidth: 150,
                                        aspectRatio: 1,
                                        borderRadius: 250,
                                        objectFit: "cover",
                                        objectPosition: "center"
                                    }}
                                    src={group.image}
                                />
                                <div style={{
                                    fontFamily: 'DM sans',
                                    textAlign: "center",
                                    fontSize: windowWidth > 768 ? "3em" : '2em',
                                    transition: 'font-size .5s',
                                    marginLeft: "1em",
                                    color: Palette.BLUE2023,
                                }}>
                                    {group.name}
                                </div>
                            </Col>
                            <Col xs={12} sm={8} style={{ margin: "20px 0", textAlign: "center" }}>
                                {group.bio}
                            </Col>
                        </Row>
                    }
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em",
                        justifyContent: "center"
                    }}
                    >
                        {curators[group.name].map(obj => renderCurator(obj))}
                    </Row>
                    {key < groups.length - 1 &&
                        <div style={{ height: 3, background: Palette.ORANGE, marginTop: 20, marginBottom: 20, width: "100%" }}></div>
                    }
                </>))}
            <div style={{ height: 3, background: Palette.ORANGE, marginTop: 20, marginBottom: 20, width: "100%" }}></div>
            <Row style={{
                            width: "100%",
                            padding: 0,
                            margin: 0,

                            justifyContent: "center"
                        }}
                        >

            <div style={{
            fontFamily: 'DM sans',
            fontSize: windowWidth > 768 ? '2.5rem' : 40,
            transition: 'font-size .5s',
            textAlign: 'center',
            marginTop: 50,
            marginBottom: 25,
            fontWeight: 700,
            color: Palette.BLUE2023,
            }}>
                Community
            </div>
                {/* <Col xs={12} sm={10} style={{ margin: "10px 0", textAlign: "center" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tellus lorem, feugiat a enim sit amet, accumsan ultricies sem. Morbi eu cursus lacus. Integer efficitur, leo sed faucibus malesuada, diam nunc pulvinar orci, sit amet convallis tortor purus ut ante. Ut id ante non sapien ultrices faucibus. Quisque et diam vel purus accumsan consectetur. Sed sapien ligula, consectetur non tellus vel, molestie volutpat augue. Nullam rutrum euismod lorem ac finibus. Maecenas tristique arcu dictum lacus rutrum, maximus ultrices ex accumsan. Vestibulum eget urna at felis pulvinar lobortis quis at orci.
                Maecenas id lacus a nisi porttitor cursus vel id massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus fermentum eros non sodales bibendum. Curabitur condimentum dictum justo et fringilla. Phasellus vulputate purus ac placerat consequat. Morbi efficitur sagittis justo et vestibulum. In in feugiat sem, vel aliquet leo. Nunc neque libero, sodales eget enim ut, tristique sollicitudin quam. Nunc rutrum ultrices quam vel sagittis. Aenean placerat nisi non nunc tempor pellentesque. Maecenas auctor ligula a orci semper, nec tempor sapien accumsan.
                </Col> */}
            {renderLogos()}
            </Row>

            </Container>
        </AppContainer>
    )
}
