import swal from "../components/CustomSweetAlert";
import ApiConfig from "./ApiConfig";

export default class API {
    static request = async (endpoint, method, body) => {
        let token = localStorage.getItem("token");

        let request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `bearer ${token}` : null,
                Accept: 'application/json'
            },
            body: JSON.stringify(body)
        };


        let response = await fetch(ApiConfig.baseUrl + endpoint, request);
        if (response.ok) {
            return await response.json();
        }

        let error = await response.json();
        if (
            error.code === 'JWT_EXPIRED' ||
            error.code === 'NO_SECRET_DEFINED' ||
            error.code === 'NO_TOKEN_PROVIDED' ||
            error.code === 'BAD_TOKEN_FORMAT' ||
            error.code === 'NO_USER_DATA' ||
            error.code === 'NO_MEMBER_DATA' ||
            error.code === 'NO_ADMIN_DATA' ||
            error.code === 'NO_SUPER_ADMIN_DATA'
        ) {
            // await swal.fire({
            //     title: 'Error',
            //     text: 'Session has expired. Please re-login.',
            //     icon: 'error',
            //     confirmButtonText: 'Ok'
            // })
            localStorage.removeItem('token');
            localStorage.removeItem('name');
            localStorage.removeItem('userId');
            return window.location.assign('/login');
        }

        throw error;

    }
}