export default class Utils {
    static logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("userId");
        window.location.reload();
    }

    static moneyFormatter = (nominal) => {
        if (!nominal) {
            return 0
        }

        let initialString = nominal + "";
        let fullString = ""

        let counter = 0;

        for (let i = initialString.length - 1; i >= 0; i--) {
            fullString = initialString.charAt(i) + (counter % 3 === 0 && counter > 0 ? "." : "") + fullString
            counter++;
        }

        return fullString
    }

    static validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

}