import { Col, Container, Row } from "react-bootstrap";
import { RiFacebookFill, RiGlobalFill, RiInstagramFill, RiMapFill, RiMapPin2Fill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";
import { CgArrowUpO } from "react-icons/cg";
import Palette from "../utils/Palette";
import { Link } from "react-router-dom";

export default function Footer({ windowWidth, menu }) {
    return (
        <Container fluid>
            <Row
                style={{ color: Palette.TURQUOISE2023, fontFamily: 'DM Sans' }}
            >
                <Col xs={12} md={5} lg={4} style={{
                    backgroundColor: windowWidth < 768 ? Palette.YELLOW2023 : Palette.YELLOW2023,
                    //color: windowWidth < 768 ? undefined : undefined,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: windowWidth < 992 ? 'flex-start' : 'flex-end',
                    paddingLeft: windowWidth < 768 ? 40 : 60,
                    paddingRight: windowWidth < 768 ? undefined : 60,
                    paddingTop: 50,
                    paddingBottom: 50,
                    //borderRight : "solid 1px white"
                }}>
                    <div style={{ maxWidth: 300 }}>
                        <Link to={'/home'}>
                            <img src={'/new/logo_fc2.png'}
                                style={{ maxHeight: 80, marginBottom: 20 }} />
                        </Link>

                        <div>
                            8-12 November 2023
                        </div>

                        <div>
                            <a href={'https://goo.gl/maps/4DbMfBc11e1L8Rak7'} style={{ color: Palette.TURQUOISE2023 }} target="_blank">
                                <br />
                                Taman Ismail Marzuki,
                                <br />
                                Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota Jakarta Pusat, Daerah Khusus
                                Ibukota Jakarta 10330
                            </a>
                        </div>

                        <div style={{ display: 'flex', marginTop: 30, marginBottom: 30 }}>


                            <a style={{ width: 50 }} href={'https://www.instagram.com/jaktent'} target="_blank">
                                <div style={{
                                    width: 46,
                                    height: 46,
                                    //border: '3px solid black',
                                    //borderRadius: 28,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 15,
                                    backgroundColor: windowWidth < 768 ? 'white' : undefined,
                                    cursor: 'pointer'
                                }}>
                                    <RiInstagramFill size={23} color={Palette.TURQUOISE2023} />
                                </div>
                            </a>

                            <a style={{ width: 50 }} href={'https://twitter.com/jaktent'} target="_blank">
                                <div style={{
                                    width: 46,
                                    height: 46,
                                    //border: '3px solid black',
                                    //borderRadius: 28,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 15,
                                    backgroundColor: windowWidth < 768 ? 'white' : undefined,
                                    cursor: 'pointer'
                                }}>
                                    <RiTwitterFill size={23} color={Palette.TURQUOISE2023} />
                                </div>
                            </a>

                            <a style={{ width: 50 }} href={'https://goo.gl/maps/4DbMfBc11e1L8Rak7'} target="_blank">
                                <div style={{
                                    width: 46,
                                    height: 46,
                                    //border: '3px solid black',
                                    //borderRadius: 28,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: windowWidth < 768 ? 'white' : undefined,
                                    cursor: 'pointer'
                                }}>
                                    <RiMapPin2Fill size={23} color={Palette.TURQUOISE2023} />
                                </div>
                            </a>
                            <a style={{ width: 50 }} href={'https://www.youtube.com/channel/UCVSV0o4hjMcXz8WHtPyLLVw'} target="_blank">
                                <div style={{
                                    width: 46,
                                    height: 46,
                                    //border: '3px solid black',
                                    //borderRadius: 28,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 15,
                                    backgroundColor: windowWidth < 768 ? 'white' : undefined,
                                    cursor: 'pointer'
                                }}>
                                    <RiYoutubeFill size={23} color={Palette.TURQUOISE2023} />
                                </div>
                            </a>
                        </div>

                        <div style={{ fontSize: 12 }}>
                            Copyright 2023. All Rights Reserved
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={7} lg={8} style={{
                    backgroundColor: Palette.YELLOW2023,
                    //color: Palette.DARK_GRAY,
                    paddingLeft: windowWidth < 768 ? 40 : 60,
                    paddingTop: windowWidth < 768 ? undefined : 100,
                    paddingBottom: windowWidth < 768 ? undefined : 100,
                }}>
                    <div style={{
                        display: windowWidth < 768 ? 'block' : 'flex',
                    }}>
                        <div>
                            <div style={{
                                fontSize: 28,
                                fontWeight: 600,
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 30,
                            }}>
                                Quick links

                                <div style={{
                                    flex: 1,
                                    backgroundColor: '#FFFFFF33',
                                    height: 1,
                                    marginLeft: 15,
                                    marginRight: 15
                                }} />
                            </div>

                            <div style={{ display: 'flex', fontSize: 19 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Row>
                                        {menu.map((item) => {
                                            return <Col md={6} xs={12}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    marginBottom: 7
                                                }}>
                                                    {/* <div style={{
                                                    width: 5,
                                                    height: 5,
                                                    borderRadius: 3,
                                                    backgroundColor: Palette.DARK_GRAY,
                                                    marginRight: 6,
                                                }}/> */}
                                                    <Link to={item.route} style={{ color: Palette.TURQUOISE2023, textDecorationLine: 'none' }}>{item.title}</Link>
                                                </div>
                                            </Col>
                                        })}
                                        <Col md={6} xs={12}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginBottom: 7,
                                            }}>
                                                {/* <div style={{
                                                    width: 5,
                                                    height: 5,
                                                    borderRadius: 3,
                                                    backgroundColor: Palette.DARK_GRAY,
                                                    marginRight: 6,
                                                }}/> */}
                                                <a href={'https://2022.jaktent.com/'} target='_blank' style={{ color: Palette.TURQUOISE2023, textDecorationLine: 'none' }}>Jaktent 2022</a>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>

                            </div>
                        </div>


                    </div>

                    <div style={{ marginTop: 60, display: 'flex', alignItems: 'center', marginBottom: 30 }}>

                     

                        <div style={{ flex: 1 }} />

                        <CgArrowUpO
                            size={30} style={{ cursor: 'pointer', marginRight: 30 }} onClick={() => {
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                            }} />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
