export default class Palette {
    static PRIMARY = "#F75B57";
    static SECONDARY = "#F7C831";
    static AGENDA_BLACK = "#101114";
    static AGENDA_GREEN = "#0bc07f";
    static AGENDA_BLUE = "#4999e8";
    static AGENDA_PURPLE = "#9d76ff";

    static PINK = "#ec3495";
    static GREEN = "#50b14d";
    static GREEN2023 = "#046e86";
    static LIGHT_GRAY = "#d2d3d5";
    static LIGHT_DARK_GRAY = "#bdbec0";
    static DARK_GRAY = "#414143";
    static GRAY = "#d2d3d5";
    static ORANGE = "#da671d";
    static RED = "#e11f26";
    static YELLOW = "#ffcf0c";

    static ORANGE2023 = '#F3632F';
    static YELLOW2023 = '#F3E800';
    static TURQUOISE2023 = '#009F96';
    static BLUE2023 = '#036E86';
    static GREYLIGHT2023 = '#F7F7F7'
    static GREYMEDIUM2023 = '#71716F'
    static AGENDA_BLACK_2023 = '#212529'
    static AGENDA_STRIPE_1 = '#f7f4c6'
    static AGENDA_STRIPE_2 = '#f7d7a7'

}
