import { Container, Modal } from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useEffect, useMemo, useState } from "react";
import QRModal from "./QRModal";
import Palette from "../utils/Palette";
import CategoryDAO from "../dao/CategoryDAO";
import { AiOutlineSearch } from "react-icons/ai";
import SearchBar from "./SearchBar";

export default function AppContainer(props) {
  const { children, removeTopPadding, title } = props;
  const [categorySession, setCategorySession] = useState([])


  let defaultHeaderMenu = [
    //{title: 'Home', route: '/home'},
    {
      title: 'Programs', route: '/programs', submenu: [
        {
          "title": "LitBeat",
          "index": 1
        },
        {
          "title": "LitFest",
          "index": 2
        },
        /* {
          "title": "LitBite",
          "index": 3
        }, */
        {
          "title": "LitFilm",
          "index": 4
        },     
        /* {
          "title": "Icon",
          "index": 5
        },
        {
          "title": "The Market",
          "index": 6
        }, */
        {
          "title": "LitTrade",
          "index": 7
        },
        {
          "title": "Jaktent x Salatiga",
          "index": 9
        },
        {
          "title": "Jaktent x Pearfest",
          "index": 10
        },
        
      ]
    },
    // { title: 'Speakers', route: '/speakers' },
    { title: 'Curators', route: '/curators' },
    { title: 'Agenda', route: '/agenda' },
    { title: 'About', route: '/about' },
    { title: 'News', route: '/news' },
    { title: 'Maps', route: '/maps' },

  ]

  const removeQueryParams = (url) => {
    let parts = url.split('/');
    if (parts.length === 1) {
      return url;
    }
    let baseUrl = parts[0];
    let queryString = parts[1];
    let params = queryString.split('&');
    let filteredParams = params.filter(function (param) {
      return !param.includes('='); // Exclude parameters with values
    });

    let newUrl = baseUrl;
    if (filteredParams.length > 0) {
      newUrl += '?' + filteredParams.join('&');
    }

    return newUrl;
  }


  const refinePath = (url) => {
    let afterDomain = url.substring(url.lastIndexOf('/') + 1);
    const newStr = url.replace(new RegExp(`/${afterDomain}`, 'g'), '');
    return newStr
  }

  defaultHeaderMenu.forEach((menu, idx) => {
    if (menu.route === window.location.pathname || menu.route === refinePath(window.location.pathname)) {
      defaultHeaderMenu[idx].selected = true;
    }
  })

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [headerMenu, setHeaderMenu] = useState(defaultHeaderMenu);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  useEffect(() => {
    if (windowWidth > 1388) setSidebarOpen(false)
  }, [windowWidth])

  useEffect(() => {

    window.scroll(0, 0)

    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])


  useEffect(() => {
    document.title = title ? `${title} - Jaktent` : "Jaktent";
  }, [title])

  const [showSearch, setShowSearch] = useState(false)

  return (
    <Container fluid style={{
      fontFamily: 'Interstate',
      padding: 0,
      backgroundColor: ['/login','/register','/forgot-password','/reset-password','/my-events','/edit-profile'].includes(window.location.pathname ) ? Palette.GREYLIGHT2023 : Palette.YELLOW2023,
      overflow: 'hidden',
    }}>
      <Header
        showSearch={showSearch}
        toogleSearch={(show) => {
          setShowSearch(show)
        }}
        removeTopPadding={removeTopPadding}
        isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} defaultHeaderMenu={defaultHeaderMenu}
        headerMenu={headerMenu} setHeaderMenu={setHeaderMenu} windowWidth={windowWidth}
        setIsQRModalOpen={setIsQRModalOpen}
      />

      <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} defaultHeaderMenu={defaultHeaderMenu}
        headerMenu={headerMenu} setHeaderMenu={setHeaderMenu} windowWidth={windowWidth}
        setIsQRModalOpen={setIsQRModalOpen} />

      {
        !removeTopPadding && <div style={{ height: 107 }}>

        </div>
      }


      {children}
      <SearchBar
        toogleSearch={(show) => {
          setShowSearch(show)
        }}
        showSearch={showSearch}></SearchBar>
      <Footer windowWidth={windowWidth} menu={headerMenu} />
      <QRModal isQRModalOpen={isQRModalOpen} setIsQRModalOpen={setIsQRModalOpen} />

    </Container>
  )
}
