import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CircularButton from "../components/CircularButton";
import Palette from "../utils/Palette";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";

export default function PressRelease() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Press Release"}>
            <div style={{
                fontFamily: 'anton',
                fontSize: windowWidth > 768 ? 80 : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 50
            }}>
                PRESS RELEASE
            </div>
            <Container style={{maxWidth: 900, marginBottom: 100,}}>
                <Row style={{marginBottom: 100}}>
                    <Col md={12}>
                        <iframe src={"/uploads/SIARAN-PERS_JAKARTA-CONTENT-WEEK-2022_COLLABRATE.pdf"} width="100%" height="700px"/>
                    </Col>
                    <Col md={12} style={{display: "flex", justifyContent: "left"}}>
                        <CircularButton
                            isLink
                            to={'/about'}
                            style={{display: "flex", flexDirection: "row", backgroundColor: Palette.YELLOW, color: "black", alignItems: "center"}}
                        >
                            <FiArrowLeft style={{marginRight: 10}} size={20}/>
                            BACK
                        </CircularButton>
                    </Col>
                </Row>
            </Container>
        </AppContainer>
    )
}