import API from "../utils/API";
import ApiConfig from "../utils/ApiConfig"
export default class SessionDAO {
    static getAllForCurrentEvent = async () => {
        return API.request('v1/sessions/' + ApiConfig.eventName, 'GET');
    }

    static getHighlightedSession = async () => {
        return API.request('v1/highlited_sessions', 'GET');
    }

    static getById = async (id) => {
        return API.request('v1/session/' + id, 'GET');
    }

    static getOneBySpeakerId = async (id) => {
        return API.request(`v1/session/speaker/${id}`, 'GET');
    }

    static getAllBySelf = async () => {
        return API.request('v1/session/mysession', 'GET');
    }

    static getConflictingSessions = async (id) => {
        return API.request(`v1/session/mysession/conflict/${id}`, 'GET');
    }
}