import AppContainer from "../components/AppContainer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import eventPicture from "../assets/temp_banner/event.jpg"
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { AiFillInstagram, AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import Palette from "../utils/Palette";
import { Link, useNavigate, useParams } from "react-router-dom";
import SpeakerDAO from "../dao/SpeakerDAO";
import LoadingProgress from "../components/LoadingProgress";
import SessionDAO from "../dao/SessionDAO";
import moment from "moment/moment";
import _ from "lodash";

export default function SpeakerDetail() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { id } = useParams();
    const [speaker, setSpeaker] = useState({});
    const [sessions, setSessions] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const navigate = useNavigate();

    const getSpeaker = async () => {
        try {
            let result1 = await SpeakerDAO.getOneById(id);
            await setSpeaker(result1);

            let result2 = await SessionDAO.getOneBySpeakerId(id);
            let sorted = _.sortBy(result2, "start_time");
            await setSessions(sorted);

            console.log(result2);
            setIsDataFetched(true);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        getSpeaker();
    }, []);

    const renderBadge = (category) => {
        let backgroundColor = 'grey';

        switch (category) {
            case 'LitBeat':
                backgroundColor = 'blue';
                break;
            case 'LitFest':
                backgroundColor = 'red';
                break;
            case 'LitBite':
                backgroundColor = 'green';
                break;
            case 'The Market':
                backgroundColor = 'purple';
                break;
            case 'Icon':
                backgroundColor = 'brown';
                break;
        }

        return (
            <div style={{ display: 'inline-flex', marginRight: 5 }}>
                <div style={{
                    fontWeight: '600',
                    color: 'white',
                    marginTop: 6,
                    marginBottom: windowWidth < 768 ? 15 : undefined,
                    backgroundColor,
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '.3em 1.1em',
                    borderRadius: 10
                }}>
                    <img src={'/icon_white_placeholder.png'} style={{
                        maxWidth: windowWidth > 768 ? 15 : 10,
                        transition: 'max-width .5s',
                        objectFit: 'contain',
                        marginRight: 8
                    }} />
                    {category}
                </div>
            </div>
        )
    }

    return (
        <AppContainer title={isDataFetched ? speaker.name : null}>
            <Container>
                <div
                    style={{
                        fontFamily: 'DM sans',
                        fontSize: windowWidth > 768 ? '1rem' : 40,
                        transition: 'font-size .5s',
                        textAlign: 'left',
                        //margin: '50px 15rem',
                        //marginBottom: 50,
                        fontWeight: 700,
                        color: Palette.BLUE2023,
                        display: 'flex',
                        alignItems: "center",
                        cursor: 'pointer',
                    }}
                    onClick={() => navigate('/speakers')}
                >
                    <FiArrowLeft style={{ marginRight: 10 }} size={20} />BACK
                </div>
                {!isDataFetched ?
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em"
                    }}>
                        <Col style={{
                            fontFamily: "Anton",
                            textAlign: "center",
                            fontSize: "6em",
                            marginTop: "0.5em",
                            marginBottom: "0.5em"
                        }} md={12}>
                            <LoadingProgress />
                        </Col>
                    </Row> :

                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: Palette.BLUE2023,
                                margin: '3.75rem 0'
                            }}>
                            <div style={{ width: '12.5rem', height: 'auto', marginRight: '2.75rem' }}>
                                <img src={speaker?.photo}
                                    style={{
                                        width: '12.5rem',
                                        height: '12.5rem',
                                        borderRadius: '12.5rem',
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        padding: 0,
                                        margin: 0,
                                    }} />
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "space-evenly",
                            }}>
                                <div>
                                    <div style={{
                                        fontSize: '2.25rem',
                                        fontWeight: 700,
                                        lineHeight: '2.75rem',
                                    }}>
                                        {speaker?.name}
                                    </div>
                                    <div style={{
                                        fontSize: '1.25rem',
                                        fontWeight: 400,
                                        lineHeight: '1.75rem',
                                        opacity: 0.8
                                    }}>
                                        {speaker?.title}
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                }}>
                                    {speaker.facebook_url &&
                                        <a href={speaker.facebook_url} target={"_blank"}>
                                            <div style={{
                                                //borderRadius: "100%",
                                                height: "2.5rem",
                                                width: "2.5rem",
                                                //border: "2px solid black",
                                                fontSize: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: Palette.BLUE2023,
                                                //marginLeft: 10,
                                                marginRight: 10
                                            }}>
                                                <AiFillFacebook style={{ width: '100%', height: 'auto' }} />
                                            </div>
                                        </a>
                                    }
                                    {speaker.instagram_url &&
                                        <a href={speaker.instagram_url} target={"_blank"}>
                                            <div style={{
                                                //borderRadius: "100%",
                                                height: "2.5rem",
                                                width: "2.5rem",
                                                //border: "2px solid black",
                                                fontSize: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: Palette.BLUE2023,
                                                //marginLeft: 10,
                                                marginRight: 10
                                            }}>
                                                <AiFillInstagram style={{ width: '100%', height: 'auto' }} />
                                            </div>
                                        </a>
                                    }
                                    {speaker.linkedin_url &&
                                        <a href={speaker.linkedin_url} target={"_blank"}>
                                            <div style={{
                                                //borderRadius: "100%",
                                                height: "2.5rem",
                                                width: "2.5rem",
                                                //border: "2px solid black",
                                                fontSize: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: Palette.BLUE2023,
                                                //marginLeft: 10,
                                                marginRight: 10
                                            }}>
                                                <AiFillLinkedin style={{ width: '100%', height: 'auto' }} />
                                            </div>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{
                            color: Palette.BLUE2023,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            opacity: 0.8,
                        }}>
                            {speaker.description}
                        </div>
                        <div style={{
                            margin: '3.75rem 0rem',
                        }}>
                            {sessions.map((obj, key) => {
                                return <Link
                                    style={{
                                        textDecoration: "none",
                                        color: Palette.BLUE2023,
                                    }}
                                    to={"/event/" + obj.id}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: windowWidth < 768 ? "column" : "row",
                                        alignItems: "center",
                                        backgroundColor: 'white',
                                        maxWidth: windowWidth < 768 ? '100%' : '50%',
                                        //gap: '1.5rem',
                                        borderRadius: '0.5rem',
                                        padding: '1.5rem',
                                    }}>
                                        <Row style={{ order: windowWidth < 768 ? 1 : 0, width: "100%", gap: '1.5rem' }}>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    order: windowWidth < 768 ? 1 : 0,
                                                    
                                                }}
                                                md={3}>
                                                <div style={{
                                                    backgroundColor: Palette.BLUE2023,
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    borderRadius: 10,
                                                    color: "white",
                                                    width: "100%",
                                                    textAlign: 'center',
                                                    lineHeight: '1.5rem'
                                                }}>
                                                    {moment(obj.start_time).format("DD MMM YYYY")}
                                                </div>
                                                <div style={{
                                                    fontSize: "0.8em",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}>
                                                    <div style={{
                                                        marginLeft: 3,
                                                        marginTop: 4,
                                                        //color: 'grey'
                                                    }}>
                                                        {moment(obj.start_time).format("HH:mm")} - {moment(obj.end_time).format("HH:mm")} WIB
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col
                                                style={{
                                                    //paddingRight: 30,
                                                    textAlign: "left",

                                                }}
                                            >
                                                <div style={{
                                                    fontWeight: "bold",
                                                    fontSize: "1.1em",
                                                    //marginTop: 15,
                                                }}>
                                                    {obj.name}
                                                </div>
                                                <div>
                                                    {obj.speakers.map((speaker, key) => {
                                                        return <> {speaker.name} {key !== obj.speakers.length - 1 && "| "} </>
                                                    })}
                                                </div>

                                                <div>
                                                    {obj.categories.map((obj) => {
                                                        return <>
                                                            {renderBadge(obj.name)}
                                                        </>
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                        <img
                                            style={{
                                                height: windowWidth < 768 ? '100%' : 'fit',
                                                width: windowWidth < 768 ? '100%' : 100,
                                                objectFit: "contain",
                                                order: windowWidth < 768 ? 0 : 1,
                                                borderRadius: 5
                                            }}
                                            src={sessions[0].image_url} />
                                    </div>

                                    {
                                        1 < sessions.length - 1 &&
                                        <div style={{
                                            background: "lightgray",
                                            height: 1,
                                            width: "100%",
                                            marginTop: 25,
                                            marginBottom: 25
                                        }}>

                                        </div>
                                    }

                                </Link>
                            })}
                        </div>
                    </div>
                }
            </Container>

            {/*      */}
            {/* <Container style={{ maxWidth: 1200 }}>
                {!isDataFetched ?
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em"
                    }}>
                        <Col style={{
                            fontFamily: "Anton",
                            textAlign: "center",
                            fontSize: "6em",
                            marginTop: "0.5em",
                            marginBottom: "0.5em"
                        }} md={12}>
                            <LoadingProgress />
                        </Col>
                    </Row> :

                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em"
                    }}>
                        <Col style={{
                            textAlign: "center",
                            marginTop: "0.5em",
                            marginBottom: "0.5em"
                        }} md={5}>
                            <div style={{
                                background: "white",
                                paddingTop: 50,
                                paddingBottom: 50,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingLeft: "10%",
                                paddingRight: "10%"
                            }}>
                                <img
                                    style={{
                                        width: 250,
                                        height: 250,
                                        borderRadius: 250,
                                        objectFit: "cover",
                                        objectPosition: "center"
                                    }}
                                    src={speaker.photo}
                                />

                                <div style={{
                                    fontWeight: "bold",
                                    fontSize: "2em",
                                    marginTop: 30,
                                }}>
                                    {speaker.name}
                                </div>
                                <div style={{
                                    fontSize: "1em",
                                }}>
                                    {speaker.title}
                                </div>

                                <p style={{
                                    color: "gray"
                                }}>
                                    <br />
                                    {speaker.description}
                                </p>

                                <div style={{
                                    background: "lightgray",
                                    height: 1,
                                    width: "80%",
                                    marginTop: 30,
                                    marginBottom: 30
                                }}>

                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    {speaker.facebook_url &&
                                        <a href={speaker.facebook_url} target={"_blank"}>
                                            <div style={{
                                                borderRadius: "100%",
                                                height: "2em",
                                                width: "2em",
                                                border: "2px solid black",
                                                fontSize: "1.1em",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "black",
                                                marginLeft: 10, marginRight: 10
                                            }}>
                                                <FaFacebook />
                                            </div>
                                        </a>
                                    }
                                    {speaker.instagram_url &&
                                        <a href={speaker.instagram_url} target={"_blank"}>
                                            <div style={{
                                                borderRadius: "100%",
                                                height: "2em",
                                                width: "2em",
                                                border: "2px solid black",
                                                fontSize: "1.1em",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "black",
                                                marginLeft: 10, marginRight: 10
                                            }}>
                                                <FaInstagram />
                                            </div>
                                        </a>
                                    }
                                    {speaker.linkedin_url &&
                                        <a href={speaker.linkedin_url} target={"_blank"}>
                                            <div style={{
                                                borderRadius: "100%",
                                                height: "2em",
                                                width: "2em",
                                                border: "2px solid black",
                                                fontSize: "1.1em",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "black",
                                                marginLeft: 10, marginRight: 10
                                            }}>
                                                <FaTwitter />
                                            </div>
                                        </a>
                                    }
                                </div>

                            </div>

                        </Col>

                        <Col
                            style={{
                                textAlign: "center",
                                marginTop: "0.5em",
                            }}
                            md={7}>
                            <Row>
                                <div style={{
                                    paddingTop: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    paddingLeft: "10%",
                                    paddingRight: "10%",
                                    width: "100%",
                                    height: "100%"
                                }}>
                                    {sessions.map((obj, key) => {
                                        return <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "#212529"
                                            }}
                                            to={"/event/" + obj.id}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: windowWidth < 768 ? "column" : "row",
                                                alignItems: "center",
                                            }}>
                                                <Row style={{ order: windowWidth < 768 ? 1 : 0, width: "100%" }}>
                                                    <Col
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            order: windowWidth < 768 ? 1 : 0,
                                                            marginTop: 15,
                                                        }}
                                                        md={4}>
                                                        <div style={{
                                                            backgroundColor: Palette.PRIMARY,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            borderRadius: 10,
                                                            color: "white",
                                                            width: "100%"
                                                        }}>
                                                            {moment(obj.start_time).format("DD MMM YYYY")}
                                                        </div>
                                                        <div style={{
                                                            fontSize: "0.8em",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}>
                                                            <div style={{
                                                                marginLeft: 3,
                                                                marginTop: 4,
                                                                color: 'grey'
                                                            }}>
                                                                {moment(obj.start_time).format("HH:mm")} - {moment(obj.end_time).format("HH:mm")} WIB
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            paddingRight: 30,
                                                            textAlign: "left",

                                                        }}
                                                    >
                                                        <div style={{
                                                            fontWeight: "bold",
                                                            fontSize: "1.1em",
                                                            marginTop: 15,
                                                        }}>
                                                            {obj.name}
                                                        </div>
                                                        <div>
                                                            {obj.speakers.map((speaker, key) => {
                                                                return <> {speaker.name} {key !== obj.speakers.length - 1 && "| "} </>
                                                            })}
                                                        </div>

                                                        <div>
                                                            {obj.categories.map((obj) => {
                                                                return <>
                                                                    {renderBadge(obj.name)}
                                                                </>
                                                            })}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <img
                                                    style={{
                                                        height: windowWidth < 768 ? '100%' : 100,
                                                        width: windowWidth < 768 ? '100%' : 100,
                                                        objectFit: "contain",
                                                        order: windowWidth < 768 ? 0 : 1,
                                                        borderRadius: 5
                                                    }}
                                                    src={obj.image_url} />
                                            </div>

                                            {
                                                key < sessions.length - 1 &&
                                                <div style={{
                                                    background: "lightgray",
                                                    height: 1,
                                                    width: "100%",
                                                    marginTop: 25,
                                                    marginBottom: 25
                                                }}>

                                                </div>
                                            }

                                        </Link>
                                    })}
                                </div>

                            </Row>

                        </Col>

                    </Row>
                }
            </Container> */}
        </AppContainer>
    )
}
