import AppContainer from "../components/AppContainer";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Card, Grid, InputGroup, Form } from "react-bootstrap";
import CircularButton from "../components/CircularButton";
import Palette from "../utils/Palette";
import { FiArrowLeft, FiArrowRight, FiSearch } from "react-icons/fi";
import img1 from '../assets/curators/Adi.jpeg'
import img2 from '../assets/curators/Diaz.jpeg'
import img3 from '../assets/curators/Dini.jpg'
import img4 from '../assets/curators/Forlent.jpg'
import ContentsDAO from '../dao/ContentsDAO'
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingProgress from "../components/LoadingProgress";
import { RiMailAddLine, RiSearchFill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
export default function News(props) {
    const newsCardref = useRef(null)
    const [loading, setLoading] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [contents, setContents] = useState([])
    const [cardHeight, setCardHeight] = useState(0)
    const navigate = useNavigate();
    const Style = {
        small: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            fontFamily: 'DM sans',
            fontSize: '0.9rem',
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: '1.4rem',
        },
        big: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            fontFamily: 'DM sans',
            fontSize: '1.3rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '2rem',
        },
    }

    const [keyword, setKeyword] = useState('')
    const fetchData = async (isSearch = false) => {

        setContents([])
        let r;
        setLoading(true)
        if (isSearch && keyword !== "") {
            r = await ContentsDAO.search(keyword)

        } else {
            r = await ContentsDAO.getAll()

        }
        const sorting = r.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        console.log('isi r', sorting)
        setContents(sorting)
        setLoading(false)
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])


    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {

    }, [keyword])
    return (
        <AppContainer title={"News"}>
            <Container>
                {/* <Row> */}

                <div style={{
                    fontFamily: 'DM sans',
                    fontSize: windowWidth > 768 ? '2.5rem' : 40,
                    transition: 'font-size .5s',
                    textAlign: 'center',
                    marginTop: 50,
                    marginBottom: 50,
                    fontWeight: 700,
                    color: Palette.BLUE2023,
                }}>
                    News
                </div>

                {/* <Col md={6} style={{ marginTop: 'auto', marginBottom: 'auto' }} className="news-search-rows">
                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ position: 'relative' }}>
                                <AiOutlineSearch size={20} style={{
                                    position: 'absolute',
                                    left: 25,
                                    top: 0,
                                    bottom: 0,
                                    color: '#046E86',
                                    margin: 'auto 0'
                                }} />

                                <input type="text" name="search" placeholder="Search News" required="" style={{
                                    fontSize: 20,
                                    borderRadius: '40px',
                                    border: '2px solid #046E86',
                                    background: '#FFF',
                                    padding: '15px 10px 15px 70px',
                                    width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                                }} value={keyword} onKeyDown={onEnter} onChange={(e) => setKeyword(e.target.value)} />
                             
                            </div>
                        </Col>
                    </Col> */}
                {/* </Row> */}
            </Container>


            <Container style={{ /* maxWidth: 900, */ marginBottom: 100, }}>

                {
                    loading ? <div className="d-flex mb-3 w-100" style={{ justifyContent: 'center', alignItems: 'center', height: '50vh' }}><div style={{ position: 'relative', bottom: 50 }}><LoadingProgress /></div></div> :
                        <Row style={{ margin: 0, padding: 0, marginBottom: 100, display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                            {[...contents].map((el, index) => {
                                let time = moment(el.created_at).from()
                                let wordsArray = el.content.split(/\s+/)
                                let desc = wordsArray.slice(0, 11).join(" ");
                                // Let's strip all the html tags
                                let div = document.createElement("div")
                                div.innerHTML = desc
                                desc = div.textContent || div.innerText || ''
                                // Thank you
                                let l = wordsArray.length
                                let duration
                                if (l == 1) duration = "1 min read"
                                else duration = Math.round(l / 200) + " mins read"
                                return (
                                    <Col
                                        ref={(ref) => {
                                            if (ref) {
                                                if (cardHeight === 0 || ref.clientHeight > cardHeight) {
                                                    setCardHeight(ref.clientHeight)
                                                    console.log('isi height', cardHeight)
                                                }
                                            }
                                        }}
                                        xl={3} md={6} sm={12} style={{ width: '18rem', margin: 0, padding: 0, marginBottom: '2rem', cursor: 'pointer' }} onClick={() => navigate('/news/' + el.id)}>
                                        <Card


                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '1rem',
                                                flex: '1 0 0',
                                                width: '18rem',
                                                height: 'auto',
                                                border: 0,
                                                color: '#ffffff',
                                                backgroundColor: Palette.GREEN2023,
                                            }}>
                                            <div style={{ width: '18rem', height: cardHeight / 2.2, background: `url(${el.thumbnail_url})`, backgroundSize: 'cover', borderRadius: '0.5rem' }} />
                                            <div className="p-2" style={Object.assign({}, Style.small, {
                                                height: 20
                                            })}>
                                                {time}
                                            </div>
                                            <div

                                                className="p-2" style={Object.assign({}, Style.big, {
                                                    height: cardHeight * 0.17
                                                })}>
                                                {el.title}
                                            </div>
                                            <div className="p-2" style={Object.assign({}, Style.small, {
                                                height: cardHeight * 0.15
                                            })}>
                                                {desc}
                                            </div>
                                            <div className="p-2" style={Style.small}>
                                                {duration}
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })}

                        </Row>
                }
                {
                    !loading && contents?.length == 0 && <div style={{
                        marginTop: 15,
                        fontSize: windowWidth > 768 ? 16 : 14,
                        transition: 'font-size .5s',
                        textAlign: "center"
                    }}>No Result Found</div>
                }



            </Container>
        </AppContainer >
    )
}